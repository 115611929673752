import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Auth } from  "../libs/utilities/helpers/Auth";

const PrivateRoute = ({ element: Element }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const isAuthenticated = Auth.isLoggedIn();
    let isAuthorized = true;

    if (isAuthenticated && location.pathname !== '/') {
        // isAuthorized = Auth.getUser().accessibility.some(item => `/${item.service}` === location.pathname);
        isAuthorized = Auth.getUser().accessibility.find(item => `/${item.service}` === location.pathname)?.status;
    }

    useEffect(() => {
        if (!isAuthenticated) {
            localStorage.clear();
            navigate("/login", { replace: true });
        } else if (!isAuthorized) {
            navigate("/", { replace: true });
        }
    }, [isAuthenticated, isAuthorized, navigate])


    return (isAuthenticated) ? <React.Fragment>{Element}</React.Fragment> : <Navigate to="/login" />;
};

export default PrivateRoute;
