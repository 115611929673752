import { createAsyncThunk } from "@reduxjs/toolkit";
import setBaseURL from "../libs/utilities/helpers/BaseURLHelper";
import { api } from "./config/axiosConfig";


const DEV_BASE_URL = process.env.REACT_APP_BASE_URL_OTHER_SERVICES;

export const reportAnalyser = createAsyncThunk(
    "analyser/reportAnalyser",
    async ({ data = {} }, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/report_analyzer", data, {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);