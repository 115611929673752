import { createSlice } from "@reduxjs/toolkit";

const componentErrorSlice = createSlice({
    name: 'componentError',
    initialState: {
        show: false,
    },
    reducers: {
        toggleComponentErrorPage: (state, action) => {
            state.show = action.payload.show;
        },
    }
})

export const { toggleComponentErrorPage }  = componentErrorSlice.actions;

export default componentErrorSlice.reducer;