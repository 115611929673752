import { useEffect, useState } from "react";
import LongArrowImage from '../../assets/images/icons/big_arrow.svg';
import "./PageTour.scss"

const PageTour = ({ show, handleShow, target, title }) => {

    const [showIntro, setShowIntro] = useState(false);
    const [isShow, setIsShow] = useState(false);


    useEffect(() => {
        const targetElement = document.getElementsByClassName(target);
        let timeoutRef;

        if (!show) {
            setIsShow(show);
            timeoutRef = setTimeout(() => {
                targetElement[0].style.zIndex = "inherit";
                setShowIntro(false);
                clearTimeout(timeoutRef)
            }, 500)
        } else {
            targetElement[0].style.zIndex = 9999;
            setShowIntro(true);
            setTimeout(() => {
                setIsShow(show);
                clearTimeout(timeoutRef)
            }, [500])
        }
        return () => {
            clearTimeout(timeoutRef)
        }
    }, [show])


    return (
        <>
            { showIntro &&
                <div className={`pageTour intro-overlay ${isShow ? 'show' : ''}`}>
                    <div className="intro-overlay-content">
                        <div className="left">
                            <div className="content">
                                <div className="prompt-text">
                                    {title}
                                </div>
                                <button className="btn-got-it" onClick={handleShow}>I GOT IT</button>
                                <div className="arrow-image">
                                    <img src={LongArrowImage} />
                                </div>
                            </div>
                        </div>
                        <div className="middle">
                        </div>
                        <div className="last">
                        </div>
                    </div>
                </div>}
        </>)
}

export default PageTour;