import { createAsyncThunk } from "@reduxjs/toolkit";
import setBaseURL from "../libs/utilities/helpers/BaseURLHelper";
import { api } from "./config/axiosConfig";

const DEV_BASE_URL = process.env.REACT_APP_BASE_URL_PCVC;

export const downloadPCVCDocument = createAsyncThunk(
    "pcvc/report/download",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/pcvc/report/download", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const generatePCVCReport = createAsyncThunk(
    "pcvc",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/pcvc", data, {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const getPCVCReport = createAsyncThunk(
    "pcvc/report",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/pcvc/report", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)


export const deletePCVCDocument = createAsyncThunk(
    "pcvc/report/delete",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/pcvc/report/delete", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const startPcvcService = createAsyncThunk(
    "/pcvc/start",
    async () => {
        console.log('hi');
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post('/pcvc/service/start');
            console.log(response);
            return response.data;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
);

export const checkPcvcStatus = createAsyncThunk(
    "/pcvc/status",
    async () => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post('/pcvc/service/get');
            console.log(response);
            return response.data;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
);