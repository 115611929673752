import { ClockCircleOutlined, DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Button, DatePicker, message, Pagination, Space, Table, Tooltip } from "antd";
import Input from "antd/es/input/Input";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkRCAStatus, deleteRCAReport, downloadRCAReport, getRCAReport, startRCAService, uploadRCAReport } from "../../apis/RcaAPI";
import "../../assets/styles/pageStyle.scss";
import InfoDialog from "../../components/InfoDialog";
import PageTour from "../../components/PageTour/PageTour";
import { formatDateToYYYYMMDD } from "../../libs/utilities/helpers/dateFormatter";
import { calculatePageSize } from "../../libs/utilities/helpers/paginationHelper";
import { hideInfoDialog, showInfoDialog } from "../../reducers/infoDialogSlice";
import { toggleTour } from "../../reducers/tourSlice";
import UploadMedia from "./components/UploadMedia";
import { PlayCircleOutline } from "@mui/icons-material";
const { RangePicker } = DatePicker;

const ComplaintAnalyser = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [allReport, setAllReport] = useState([]);
    const [filteredReport, setFilteredReport] = useState([]);
    const [filteredReportWithoutSort, setFilteredReportWithoutSort] = useState([]);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState([]);
    const [serviceStatusLoading, setServiceStatusLoading] = useState(true);
    const [serviceRunning, setServiceRunning] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 3,
        total: 0
    })

    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: '',
    })

    const dateFormat = 'YYYY-MM-DD';
    const defaultDateRange = [
        dayjs().subtract(6, 'days'),
        dayjs()
    ];

    const columns = [
        {
            title: 'Name',
            dataIndex: 'document_name',
            key: 'document_name',
            sorter: (a, b) => { }
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            // sorter: (a,b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        },
        {
            title: 'Status',
            dataIndex: 'is_processed',
            key: 'is_processed',
            render: (_, record) => (
                <> {record.is_processed ? <div style={{ color: "" }}>Completed</div> : <div style={{ color: "" }}>In Progress</div>} </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="small">
                    {
                        record.is_processed ?
                            <Tooltip
                                placement="top" title="Download document"
                            >
                                <Button type="text"
                                    loading={selectedDocument.some(item => item === record.document_name) &&
                                        downloadLoading} onClick={() => downloadrcaReport(record.document_name, record.document_id)}
                                >
                                    <DownloadOutlined />
                                </Button>
                            </Tooltip>
                            :
                            <Tooltip title="Document not generated">
                                <Button type="text"><ExclamationCircleOutlined style={{ color: "gray" }} /></Button>
                            </Tooltip>
                    }
                    <Tooltip title="Delete document">
                        <Button onClick={() => startRCADelete(record.document_id)} type="text" >
                            <DeleteOutlined />
                        </Button>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const startDate = formatDateToYYYYMMDD(dayjs().subtract(6, 'days'));
    const endDate = formatDateToYYYYMMDD(dayjs());

    const startService = async () => {
        setServiceStatusLoading(true);
        await dispatch(startRCAService());
        verifyStatus();
    }

    const verifyStatus = async () => {
        const response = await dispatch(checkRCAStatus());
        if (response.payload?.data?.running_task_ids?.length > 0) {
            setServiceRunning(true);
        } else {
            setServiceRunning(false);
        }
        setServiceStatusLoading(false);
    }

    useEffect( () => {
        verifyStatus();
        const interval = setInterval(async () => {
            console.log('Running function every second');
            verifyStatus();
        }, 60 * 10 * 10 * 10); //Check the status every minutes (60 seconds)
        setDateRange({ startDate, endDate })
        getReport(startDate, endDate)
        // eslint-disable-next-line
        window.addEventListener('resize', handleResize);
        // const interval = setInterval(() => {

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', handleResize)
        }
    }, []);


    const handleResize = () => {
        calculatePageSize(setPagination);
    }

    const [selectedRCAIdToDelete, setSelectedRCAIdToDelete] = useState();

    const startRCADelete = (doc_id) => {
        setSelectedRCAIdToDelete(doc_id);
        dispatch(showInfoDialog({ title: "Are you sure you want to delete?", description: "" }));
    }

    const confirmRCADelete = async () => {
        try {
            const response = await dispatch(deleteRCAReport({ _id: selectedRCAIdToDelete }))
            if (!response.error) {
                message.success("File deleted successfully");
                dispatch(hideInfoDialog());
                getReport(dateRange.startDate, dateRange.endDate)
            }
        } catch (e) {
            message.warning("Something went wrong!");
        }
    }

    const getReport = async (startDate, endDate) => {
        setFetchLoading(true);
        const response = dispatch(getRCAReport({ startDate, endDate }));
        const data = await response;
        if (!data.error) {
            setSearchText('');
            // {document_name, is_processed, createdAt, updatedAt}
            const sortedData = data.payload.data.documents.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            const lists = sortedData.map((item, index) => (
                {
                    id: index,
                    document_id: item._id,
                    document_name: item.document_name,
                    is_processed: item.is_processed,
                    createdAt: formatDateToYYYYMMDD(item.createdAt), updatedAt: item.updatedAt
                }));
            setPagination({ ...pagination, total: lists.length })
            setAllReport(lists);
            setFilteredReport(lists);
            setFilteredReportWithoutSort([...lists]);
            handleResize();
        }
        setFetchLoading(false);
    }

    const handleDateFilter = (dates, dateStrings) => {
        if (!dates) {
            return;
        }
        let startDate = formatDateToYYYYMMDD(dateStrings[0]);
        let endDate = formatDateToYYYYMMDD(dateStrings[1]);
        setDateRange({ startDate, endDate })
        getReport(startDate, endDate);
    };

    const [tableSorter, setTableSorter] = useState(null);

    const sortTableData = (pagination, filters, sorter) => {
        setTableSorter(sorter);
        if (sorter.columnKey === "document_name") {
            if (!sorter.order) {
                setFilteredReport(filteredReportWithoutSort);
                return;
            }
            const filtered = filteredReport.sort((a, b) =>
                sorter.order === "ascend" ? a.document_name.localeCompare(b.document_name) : b.document_name.localeCompare(a.document_name));
            setFilteredReport([...filtered]);
        }
    }

    const handlePageChange = (page, pageSize) => {
        setPagination({ ...pagination, current: page, pageSize })
    }

    const startIdx = (pagination.current - 1) * pagination.pageSize;
    const endIdx = startIdx + pagination.pageSize;
    const displayData = filteredReport.slice(startIdx, endIdx)
    const getEndOfDay = (date) => {
        const endOfDay = new Date(date);
        endOfDay.setHours(23, 59, 59, 999);
        return endOfDay;
    };

    const disabledDate = current => {
        return current && current > getEndOfDay(new Date());
    };

    const downloadrcaReport = async (document_name, document_id) => {
        setSelectedDocument([...selectedDocument, document_name]);
        setDownloadLoading(true);
        try {
            const response = await dispatch(downloadRCAReport({ '_id': document_id }));
            const binaryString = atob(response.payload.data);
            const arrayBuffer = new ArrayBuffer(binaryString.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryString.length; i++) {
                uint8Array[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', document_name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (e) {
            message.error('Failed to download report. Please try again.');
        } finally {
            setSelectedDocument((prev) => prev.filter(item => item !== document_name));
            setDownloadLoading(false);
        }
    }

    const handleSearch = (e) => {
        const searchString = e.target.value;
        setSearchText(searchString);
        const allData = [...allReport];
        const filtered = allData.filter(item => item.document_name.toLowerCase().includes(searchString.trim().toLowerCase()))
        setPagination({ ...pagination, current: 1, pageSize: 3, total: filtered.length })

        setFilteredReport(filtered);
        setFilteredReportWithoutSort([...filtered]);

        if (tableSorter?.columnKey === "document_name") {
            if (!tableSorter.order) {
                setFilteredReport(filteredReportWithoutSort);
                return;
            }
            const tempFiltered = filtered.sort((a, b) =>
                tableSorter.order === "ascend" ? a.document_name.localeCompare(b.document_name) : b.document_name.localeCompare(a.document_name));
            setFilteredReport([...tempFiltered]);
        }

    }

    const handleFileSelect = (file) => {
        setFileList(file)
    };

    const generateReport = async () => {
        if (fileList.length === 0) {
            message.warning("Please upload a file")
            return;
        }
        setLoading(true);
        try {
            const data = {
                file: fileList[0],
                type: "xlsx"
            }
            const response = await dispatch(uploadRCAReport(data));
            if (!response.error) {
                message.success("File uploaded successfully. File will display in the table after process completion")
                setFileList([])
                getReport(dateRange.startDate, dateRange.endDate);
            }
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    const tourState = useSelector((state) => state.tour);
    const handleTourClose = () => {
        dispatch(toggleTour({ show: false }))
        // setShowHandAnimation(false);
    }


    return (
        <>
            <div className="rca-container">
                <InfoDialog onConfirm={confirmRCADelete} />
                <PageTour show={tourState.show} handleShow={handleTourClose} target="ant-upload-drag" title="Start uploading excel file to get analyser report" />
                <div className="file-table">
                    <div className="table-actions">
                        <Input size="middle" value={searchText} onChange={(e) => handleSearch(e)} placeholder="Search" />
                        <div>
                            <RangePicker
                                className="file-datepicker"
                                onChange={handleDateFilter}
                                disabledDate={disabledDate}
                                defaultValue={defaultDateRange}
                                format={dateFormat}
                                allowClear={false}
                            />
                            <Tooltip title={serviceRunning ? "Running" : "Start"}>
                                <Button style={{ marginLeft: "1rem" }} type="primary" onClick={startService} size="large" shape="circle" disabled={serviceRunning || !displayData.length} icon={serviceRunning ? <ClockCircleOutlined /> : <PlayCircleOutlined />} loading={serviceStatusLoading} />
                            </Tooltip>
                        </div>
                    </div>

                    <Table
                        columns={columns}
                        dataSource={displayData}
                        loading={fetchLoading}
                        style={{ overflowY: "auto" }}
                        pagination={false}
                        rowKey={(record) => record.id}
                        onChange={sortTableData}
                    />
                    <Pagination
                        current={pagination.current}
                        pageSize={pagination.pageSize}
                        total={+pagination.total}
                        onChange={handlePageChange} style={{ marginTop: "5px", float: "right" }} />

                </div>
                {/* ======= */}
                <div>
                    <UploadMedia
                        handleFileSelect={handleFileSelect}
                        fileList={fileList}
                    />
                    <div className="actions">
                        <Button type="primary" onClick={generateReport} disabled={loading}>
                            <div className='text'>Submit</div>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ComplaintAnalyser;