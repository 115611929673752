import { createAsyncThunk } from "@reduxjs/toolkit";
import setBaseURL from "../libs/utilities/helpers/BaseURLHelper";
import { api } from "./config/axiosConfig";

const DEV_BASE_URL = process.env.REACT_APP_BASE_URL_OTHER_SERVICES;

export const getEarlyClaimsReport = createAsyncThunk(
    "/summary/claims/report",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/summary/claims/report", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const downloadEarlyClaimsReport = createAsyncThunk(
    "/summary/claims/report/download",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/summary/claims/report/download", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteEarlyClaimsReport = createAsyncThunk(
    "/summary/claims/report/delete",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/summary/claims/report/delete", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const uploadEarlyClaimReport = createAsyncThunk(
    "/summary/claims",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/summary/claims", data, {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const startEarlyClaimsService = createAsyncThunk(
    "/summary/claims/start",
    async () => {
        console.log('hi');
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post('/summary/claims/service/start');
            console.log(response);
            return response.data;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
);

export const checkEarlyClaimsStatus = createAsyncThunk(
    "/summary/claims/status",
    async () => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post('/summary/claims/service/get');
            console.log(response);
            return response.data;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
);