import DeleteIcon from "../../assets/images/icons/delete_icon.svg"
import { defaultFolder } from "../../components/UploadFolderModal"

const DropdownFileItem = ({ folder, file, itemType, confirmDelete }) => {

    const handleFolderDeleteConfirm = (event, folder) => {
        if (event.type === "click")
            confirmDelete({ type: "folder", data: folder })
    }

    const handleFileDeletConfirm = (event, data) => {
        if (event.type === "click")
            confirmDelete({ type: "file", data })
    }


    return (
        <div>
            {(itemType === "folder") ? (
                <>
                    <div
                        className="selected-item" style={{ display: "flex", justifyContent: "space-between", gap: "20px", alignItems: "center" }}>
                        <div className="title">{folder.folder_name}</div>
                        {!defaultFolder.some(item => item.name == folder.folder_name) && <img src={DeleteIcon} width={13} onClick={(e) => handleFolderDeleteConfirm(e, folder)} />}
                    </div>
                </>
            ) : (
                <>
                    <div
                        className="selected-item" style={{ display: "flex", justifyContent: "space-between", gap: "20px", alignItems: "center" }}>
                        <div className="title">{file.name}</div>
                        <img src={DeleteIcon} width={13} onClick={(e) => handleFileDeletConfirm(e, { folder, file })} />
                    </div>

                </>
            )}

        </div>
    )
}

export default DropdownFileItem;