import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button, Card, Input, message, Spin } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';

import "../assets/styles/bot2.scss";
import ChatAnimationImage from "../assets/images/icons/chat_circle.gif"
import UserIcon from "../assets/images/27470347_7309691 1.svg";
import BotIcon from "../assets/images/1100838311-preview 1.svg";
import { generateAnswer, getPdf } from '../apis/DocGPTAPI';
import { useDispatch } from 'react-redux';
import Scrollbar from "perfect-scrollbar";
import { v4 as uuidv4 } from 'uuid';
import HandAnimatedIcon from "../assets/images/icons/animated_hand.gif"
import "perfect-scrollbar/css/perfect-scrollbar.css";


const suggestions = [
  "Who can avail smart exit benefit?",
  "What will happen if there is a sudden death of the policy holder?",
  "Can I cancel the policy in the middle or after the purchase of the policy?",
  "What will be the Max. Basic Sum Assured amount ?",
  "What is the SA Factor for the policy year of 6 to 10 years?",
  "What is the premium installments options available?",
  "What are the different frequency modes available for premium payment?",
  "What will be the minimum sum assured amount for Life Long Income Option?",
  "What is the grace period?",
  "What will be the minimum surrender value for this plan?",
  "What is the age limit?",
];

const Chatbot2 = ({ selectedFile, folderList, showTour, handleInputFocus }) => {
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const chatContentRef = useRef(null);
  const [selectedPDF, setSelectedPDF] = useState('');
  const [pdfLoading, setPDFLoading] = useState(false);

  const [totalFileCount, setTotalFileCount] = useState(0);

  useEffect(() => {
    let totalCount = 0;
    folderList.forEach(item => {
      totalCount += item.file.length;
    })
    setTotalFileCount(totalCount);
  }, [folderList])


  useEffect(() => {
    let ps = new Scrollbar(chatContentRef.current);
    return () => {
      if (ps) {
        ps.destroy();
        ps = null;
      }
    }
  }, []);


  const inputRef = useRef(null);
  const debounceTimer = useRef(null);
  const delay = 500;


  const handleChange = useCallback(() => {
    const newInputValue = inputRef.current.input.value;
    setUserInput(newInputValue);
  }, [suggestions, delay, selectedFile]);
  const handleChatSubmit = async (selectedMessage) => {
        if (!selectedFile || selectedFile.length === 0) {
      message.error("Please select a file");
    } else if (!selectedMessage.trim()) {
      message.error("Invalid selected message");
    } else {
      setUserInput('');

      let uuid = uuidv4();

      let queryData = {
        type: 'user',
        uuid: uuid,
        query: selectedMessage,
        response: null,
        files: [],
        isLoading: true,
        content: selectedMessage,
        responseType: "success"
      };

      setChatHistory((prevMessages) => [
        ...prevMessages,
        queryData
      ]);

      scollChatToBottom();

      const requestBody = {
        file_id: selectedFile,
        max_token: 500,
        query: selectedMessage,
        query_id: uuid,
      };

      try {
        const response = await dispatch(generateAnswer(requestBody));
        console.log(response);
        if (response.error) {
          let query_id = response.meta.arg.query_id;
          setChatHistory((previousHistory) => {
            let updatedHistory = previousHistory;
            const targetIndex = updatedHistory.findIndex(item => item.uuid === query_id);
            updatedHistory[targetIndex].response = "Something went wrong!";
            updatedHistory[targetIndex].isLoading = false;
            updatedHistory[targetIndex].responseType = "error";
            return [...updatedHistory];
          });
        } else {
          const answer = response.payload.data.answer; // Extract the answer
          const metaData = response.payload.data.meta; // Extract the meta string
          const files = response.payload.data.files?.map(item => ({ ...item, name: getFileName(item.fileId) })) || [];

          // Parse the meta string into an array of objects
          // const metaData = JSON.parse(metaString.replace(/'/g, '"'));

          setChatHistory((previousHistory) => {
            let updatedHistory = previousHistory;
            const targetIndex = updatedHistory.findIndex(item => item.uuid === response.meta.arg.query_id);
            updatedHistory[targetIndex].response = answer;
            updatedHistory[targetIndex].files = files;
            updatedHistory[targetIndex].metaData = metaData; // Store the parsed meta data
            updatedHistory[targetIndex].isLoading = false;
            updatedHistory[targetIndex].responseType = "success";
            return [...updatedHistory];
          });
        }
      } catch (error) {
        // Handle any other errors
      } finally {
        scollChatToBottom();
      }
    }
  };


  const handleEnterKeyPress = (e) => {
    if (e.key === 'Enter' && userInput.trim() !== '') {
      handleChatSubmit(inputRef.current.input.value);
    }
  };


  const scollChatToBottom = () => {
    setTimeout(() => {
      chatContentRef?.current?.scrollTo({
        top: chatContentRef.current.scrollHeight,
        behaviour: "smooth"
      })
    }, 500)
  }

  const handleChatInputClick = () => {
    handleInputFocus();
  }

  const getFileName = (fileId) => {
    const file = folderList?.find(item => item.file?.find(file => file?.id === fileId))?.file?.find(item => item.id === fileId);
    return file?.name;
  }

  const downloadPDF = async (name, fileId, page) => {
    setSelectedPDF(fileId);
    const folder = folderList.find(item => item?.file?.find(file => file.id === fileId));
    const file = folder.file.find(item => item?.id === fileId);

    const data = {
      folderName: folder?.folder_name,
      fileName: file?.name,
    };
    setPDFLoading(true);

    const response = await dispatch(getPdf(data));

    if (!response.error) {
      const binaryString = window.atob(response.payload.data);
      const binary = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        binary[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([binary], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const pdfViewerUrl = `${url}#page=${page}`;
      window.open(pdfViewerUrl, "_blank");

      if (blob) {
        setPDFLoading(false);
      }
    } else {
      setPDFLoading(false);
    }
  };


  return (
    <Card className="chatbot-card" size="large">
      <>
        {chatHistory.length === 0 ? (
          <div className="chatbot-content" ref={chatContentRef} style={{ justifyContent: "center" }}>
            <img className='animation' src={ChatAnimationImage} alt="waiting-gif" width={100} height={100} />
            <p className='text5'>
              {totalFileCount.length === 0 ?
                "Upload a document to let's get started" :
                (selectedFile.length === 0 ? "Select or upload a new document and let's get started" : <span style={{ color: "#C5C0DA" }}>I am waiting for a prompt</span>)}
            </p>
          </div>
        ) :

          <div className="chatbot-content" ref={chatContentRef}>
            {
              chatHistory.length > 0 ? chatHistory.map((item, index) => (
                <div key={index} style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                  <div className={`chat-message user`}>
                    <span className='user-message success'>{item.query}</span>
                    <img className='user-icon' src={UserIcon} alt="user-icon" width={40} height={40} />
                  </div>
                  <div className={`chat-message bot`}>
                    <img className='bot-icon' src={BotIcon} alt="bot-icon" width="auto" height={40} />
                    {
                      item.isLoading ? <Spin size="small" style={{ marginLeft: 8 }} /> :
                        <div className={`bot-message ${item?.responseType}`}>
                          <div
                            style={{ whiteSpace: "pre-line" }}
                            dangerouslySetInnerHTML={{
                              __html: item?.response
                                .replace(/\n/g, '<br/>')             // Replaces \n with line break
                                .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;') // Replaces \t with four spaces
                                .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')  // Replaces **text** with <b>text</b>
                                .replace(/\*(.*?)\*/g, '<i>$1</i>')   // Replaces *text* with <i>text</i>
                            }}
                          />
                          {item?.metaData && item?.metaData?.length > 0 && (
                            <div style={{ color: "gray", fontSize: "12px", borderTop: "1px solid gray", paddingTop: "4px", marginTop: "4px" }}>
                              This response is generated by Gen AI. Please verify the information by clicking on the given PDF link.
                            </div>
                          )}
                          <div style={{ display: "flex", flexDirection: "column", gap: "7px", maxHeight: "45px", overflowY: "auto", paddingTop: "0px" }}>
                            {item?.metaData?.map((metaItem, idx) => {
                              return (
                                <div key={idx} style={{ display: "flex", gap: "5px", fontSize: "12px", color: "black" }}>
                                  <div style={{ display: "flex", alignItems: "flex-start", gap: "5px", cursor: "pointer" }} onClick={() => downloadPDF(getFileName(metaItem?.file_id), metaItem?.file_id, 0)}>
                                    <FilePdfOutlined style={{ marginTop: "2px" }} />{getFileName(metaItem?.file_id)}
                                  </div>
                                  <div>
                                    - (
                                    {Array.isArray(metaItem?.page) ? (
                                      metaItem?.page?.map((page, index) => {
                                        return (
                                          <span key={index} onClick={() => downloadPDF(getFileName(metaItem?.file_id), metaItem?.file_id, page)} style={{ color: "#4b8bf2", cursor: "pointer" }}>
                                            Page no: {page}{index !== metaItem?.page?.length - 1 && ", "}
                                          </span>
                                        );
                                      })
                                    ) : (
                                      <span onClick={() => downloadPDF(getFileName(metaItem?.file_id), metaItem?.file_id, metaItem?.page)} style={{ color: "#4b8bf2", cursor: "pointer" }}>
                                        Page no: {metaItem?.page}
                                      </span>
                                    )}
                                    )
                                  </div>
                                  {(selectedPDF === metaItem?.file_id) && pdfLoading && <span>Loading...</span>}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                    }
                  </div>
                </div>
              )) : null
            }
          </div>
        }
      </>

      <div className="chat-input" style={{ flexDirection: "column" }}>
        <div className='chat-input-content'>
          <div className='chat-input-field'>
            {/* {showSuggestions && (
              <div className="suggestion-container">
                {inputRef.current.input.value && filteredSuggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="suggestion"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            )} */}
            <Input
              placeholder="Type your message..."
              type="text"
              ref={inputRef}
              value={userInput}
              onChange={handleChange}
              onKeyPress={handleEnterKeyPress}
              onFocus={() => handleChatInputClick()}
            />
          </div>
          <Button type="primary" onClick={() => handleChatSubmit(inputRef.current.input.value)}>
            Submit
          </Button>
        </div>
        <div style={{ textAlign: "center", width: "100", fontSize: "12px", paddingRight: "50px", marginTop: "3px" }}>
          Enterprise GPT is prone to factual inaccuracies; It is advisable to verify the information.
        </div>
        {showTour && <div className="hand-animated" style={{ position: "absolute", zIndex: "10000", bottom: "-20px" }}>
          <img src={HandAnimatedIcon} width={80} />
        </div>}
      </div>

    </Card>
  );
};

export default Chatbot2;
