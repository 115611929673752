import React, { useCallback } from 'react';
import { Upload, message, InputNumber, ConfigProvider } from 'antd';
import ArrowUpIcon from "../../../assets/images/icons/arrow_up.svg";
import { CloseRounded } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { toggleTour } from '../../../reducers/tourSlice';
const { Dragger } = Upload;

const ReportUploadDragger = ({ handleFileSelect, fileList, handelWordChange, wordValue }) => {
    const dispatch = useDispatch();

    const beforeUpload = (file) => {
        if (file.type === "application/pdf") {

            if ((file.size / 1024 / 1024) > 100) {
                message.error('File must be smaller than 100MB!');
                return Upload.LIST_IGNORE;
            }

            // Checking and removing duplicate file
            const isDuplicate = fileList.some(existingFile => existingFile.name === file.name);
            if (isDuplicate) {
                handleFileSelect([]);
                return Upload.LIST_IGNORE;
            }

            return true;
        } else {
            message.error('Invalid File format. File should be in pdf format');
            return Upload.LIST_IGNORE;
        }
    };

    const handleFileChange = (file) => {
        handleFileSelect(file?.fileList);
    };

    const customRequest = ({ file, onSuccess }) => {
        if (file && file.name) {
            onSuccess("ok");
        }
    }

    const onRemove = (index) => {
        const newFileList = fileList.filter((_, i) => i !== index);
        handleFileSelect(newFileList);
    };

    const handleClickOnDragger = () => {
        dispatch(toggleTour({ show: false }));
    }
    const stopdraggerPropogation = (event) => {
        event.stopPropagation();
    }
    const handleNwordChange = useCallback((value) => {
        handelWordChange(value)
    }, []);
    return (
        <div>
            <Dragger
                fileList={fileList}
                multiple={true}
                beforeUpload={beforeUpload}
                onChange={handleFileChange}
                customRequest={customRequest}
                showUploadList={false}
                onClick={handleClickOnDragger}
                disabled={fileList.length > 0}
            >
                <div className='upload-container-report' >
                    {
                        fileList.length === 0 ?
                            <div className='upload-action'>
                                <div className='title'>Drag and drop here or</div>
                                <button className="btn-outlined">
                                    <div className='btn-outlined-content'>
                                        <div className='text'>Upload documents </div>
                                        <img width={29} src={ArrowUpIcon} />
                                    </div>
                                </button>
                            </div>
                            :
                            <div className="upload-action" style={{ zIndex: 9999 }} onClick={() => { return; }}>
                                <div className="selectedList" >
                                    
                                        {
                                            fileList.map((item, index) => {
                                                return (
                                                    <div className="filelistdiv" key={index}>

                                                        <p className="name" style={{ margin: "0" }}>{item.name}</p>
                                                        <CloseRounded className="remove" onClick={() => onRemove(index)} />
                                                    </div>

                                                )
                                            })
                                        }
                                </div>
                            </div>
                    }
                    <div className='upload-action' onClick={stopdraggerPropogation} style={{ marginTop: "3px" }}>
                        <div className="file-report">
                            <span className="file-report-content">Select length of report</span>
                            <div className="input-number-div">
                                <div className="btn-outlined">
                                    <div className="btn-outlined-content">
                                        <ConfigProvider
                                            theme={{
                                                components: {
                                                    InputNumber: {
                                                        activeShadow: "0 0 0 2px rgba(255, 255, 255, 0.1)"
                                                    },
                                                },
                                            }}
                                        >
                                            <InputNumber size="large" min={30} max={300} defaultValue={wordValue} className="input-number" onChange={handleNwordChange} />
                                        </ConfigProvider>
                                    </div>
                                </div>
                                <p className="inputwords">Words</p>
                            </div>
                        </div>
                    </div>
                </div>

            </Dragger>

        </div>
    );
};

export default ReportUploadDragger;
