const errorCodes = {
    ERR0001: "Something went wrong, please try again later",
    // popup
    ERR0002: "Token Expired", 
    // ERR0003: "Authentication Token Missing",
    ERR0004: "User not found",
    // ERR0005: "Invalid Token",
    // ERR0006: "Folder not found",
    ERR0007: "Inactive User, please contact administrator",
    ERR0008: "Invalid Credentials",
    ERR0009: "User already exists",
    // ERR0010: "Bad Request",
    // ERR0011: "Invalid Transaction ID", 
    // ERR0012: "Invalid User ID", 
    // ERR0013: "Invalid source",
    // ERR0014: "Invalid Prompt", 
    // ERR0015: "Invalid nword",
    // ERR0016: "Invalid Max Tokens",
    // ERR0017: "File is Required",
    // ERR0018: "Invalid File ID",
    // ERR0019: "Query is Missing",
    // ERR0020: "Type is Missing",
    // ERR0021: "Invalid Folder ID",
    // ERR0022: "Invalid Embedding Type", 
    // ERR0023: "Folder Name is Missing",
    ERR0024: "File format should be PDF", 
    ERR0025: "Please upload file",
    // ERR0026: "Username is Missing", 
    ERR0027: "Invalid Password",
    // ERR0028: "Gender is missing",
    // ERR0029: "Department is missing",
    // ERR0030: "Password is missing",
    // ERR0031: "Chat prompt is missing",
    // ERR0032: "Conversation history is missing",
    // ERR0033: "Invalid Conversation ID"
    ERR0034: "File is already uploaded"
}
 
export default errorCodes;