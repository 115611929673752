import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';

const PDFViewer = ({ pdfData, show, handleClose }) => {
    const [pdfSrc, setPdfSrc] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(show && pdfData) {
          const url = URL.createObjectURL(pdfData.blob);
          setPdfSrc(`${url}#page=${pdfData.page}`);
          }
    }, [show, pdfData])


    
    const handleCancel = () => {
      setPdfSrc("")
      handleClose();
    };


  return (
    <>
      <Modal
        title={pdfData.name}
        open={show}
        onCancel={handleCancel}
        footer={null}
        width={800} // Adjust width as needed
      >

        {loading ? <div>Loading...</div> : 
        <iframe
          title="PDF Viewer"
          src={pdfSrc}
          width="100%"
          height="430px"
        ></iframe>}
      </Modal>
    </>
  );
};

export default PDFViewer;
