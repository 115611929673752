import UserIcon from "../assets/images/icons/user.png";
import { LineChartOutlined, LogoutOutlined } from "@ant-design/icons";
import Dropdown from "antd/es/dropdown/dropdown";
import Modal from "antd/es/modal/Modal";
import { Button, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ChatAIIcon from "../assets/images/icons/chat_AI_icon.svg";
import ChatAIIconActive from "../assets/images/icons/chat_AI_icon_active.svg";
import ReportAnalyserIcon from "../assets/images/icons/report_analyser.svg";
import ReportAnalyserActiveIcon from "../assets/images/icons/report_analyser_active.svg";
import DocumentGPTIcom from "../assets/images/icons/document_gpt_active.svg";
import SummarizerIcon from "../assets/images/icons/summarizer.svg";
import MenuIcon from "../assets/images/icons/menu_icon.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Auth } from  "../libs/utilities/helpers/Auth";
import { useDispatch } from "react-redux";
import { toggleComponentErrorPage } from "../reducers/componentErrorSlice";

export const appMenu = [
    {
        title: "Chat AI",
        route: "chat-ai",
        info: "A generative AI based chatbot to process human-like conversations",
        icon: ChatAIIcon,
        activeIcon: ChatAIIconActive,
        width: 141
    },
    {
        title: "Enterprise GPT",
        info: "A generative AI tool that allow users to ask questions about their documents, such as asking product feature from a product brochure",
        icon: SummarizerIcon,
        activeIcon: DocumentGPTIcom,
        route: "enterprise-gpt",
        width: 191
    },
    {
        title: "Process Buddy",
        info: "A generative AI tool that allow users to ask questions about their documents, such as asking product feature from a product brochure",
        icon: SummarizerIcon,
        activeIcon: DocumentGPTIcom,
        route: "process-buddy",
        width: 190
    },
    {
        title: "Report Analyser",
        info: "A  Gen AI tool that allows users to ask questions about their documents.",
        icon: SummarizerIcon,
        activeIcon: DocumentGPTIcom,
        route: "report-analyser",
        width: 225
    },
    {
        title: "Report Generator",
        info: "Generates financial report about a company basis DRHP prospectus, annual reports, financial numbers",
        icon: ReportAnalyserIcon,
        activeIcon: ReportAnalyserActiveIcon,
        route: "report-generator",
        width: 208
    },

    {
        title: "PCVC",
        info: "An AI generated diarized transcripts of PCVC audit videos/calls",
        icon: ReportAnalyserIcon,
        activeIcon: ReportAnalyserActiveIcon,
        route: "pcvc",
        width: 128.469
    },
    {
        title: "Complaint Analyser",
        info: "A Generative AI service to generate concise summaries for complaints RCA about customer interactions across touchpoints and systems",
        icon: SummarizerIcon,
        activeIcon: DocumentGPTIcom,
        route: "complaint-analyser",
        width: 222.731
    },
    {
        title: "Pre-claims Assistant",
        info: "Generation of case gist basis customer details for suspected early claims cases",
        icon: SummarizerIcon,
        activeIcon: DocumentGPTIcom,
        route: "pre-claims",
        width: 216
    },
    {
        title: "Call Repository",
        info: "A service to upload call recordings and xlsx files  ",
        icon: ReportAnalyserIcon,
        activeIcon: ReportAnalyserActiveIcon,
        route: "call-repository",
        width: 200
    }
];

const CustomHeader = ({ collapsed }) => {
    const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL;
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [accessibleMenu, setAccessibleMenu] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const user = Auth.getUser();
    
    const items = [
        {
            label: <a onClick={() => setOpen(true)}>Logout</a>,
            key: '1',
            icon: <LogoutOutlined />,
            danger: true,
            
        },
    ];

    useEffect(() => {
        let accessibility = user.accessibility.filter(item => item.status);
        if (accessibility) {
            const menus = appMenu.filter(item => accessibility.some(menu => menu.service === item.route));

            menus.sort((a, b) => {
                const priorityA = accessibility.find(item => item.service === a.route);
                const priorityB = accessibility.find(item => item.service === b.route);
                if (priorityA && priorityB) {
                    return priorityA.index - priorityB.index;
                } else if (priorityA) {
                    return -1;
                } else if (priorityB) {
                    return 1;
                }
                return 0;
            });
            setAccessibleMenu(menus);
        }

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [])


    const visibleMenuItems = accessibleMenu.filter((item, index) => {
        const totalItemWidth = accessibleMenu
            .slice(0, index + 1)
            .reduce((acc, curr) => acc + curr.width, 0);
        return totalItemWidth + (collapsed ? 200 : 408) <= windowWidth;
    });

    const invisibleMenuItems = accessibleMenu.filter(item => !visibleMenuItems.includes(item))


    const handleBtnClick = () => {
        dispatch(toggleComponentErrorPage({ show: false }))
    }

    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
    };

    return (
        <>
            <header>
                <div className="header">
                    <div className="header-text">Home</div>
                    <div className="right-nav">
                        {/* <div className="icons">
                        <img src={NotificationIcom} />
                        <img src={MoonIcon} />
                    </div> */}
                        {user.Department === 'Data Labs' && <a href={DASHBOARD_URL} target="_blank"><LineChartOutlined style={{ fontSize: "20px", cursor: "pointer" }} /></a>}
                        <div className="user" >
                            <Dropdown menu={{ items }} trigger={["click"]}>
                                <img src={UserIcon} alt="usericon" style={{ cursor: "pointer" }} />
                            </Dropdown>
                        </div>
                    </div>
                </div>


                <div className="card-container">
                    {visibleMenuItems.map((item, index) => (
                        <Tooltip placement="top" title={item.info} key={index}>
                            <Link to={`/${item.route}`} className="card-link">
                                <button
                                    onClick={handleBtnClick}
                                    className={`custom-button ${location.pathname === `/${item.route}` && "active"
                                        }`}
                                >
                                    <img
                                        width="22"
                                        height="22"
                                        src={
                                            location.pathname === `/${item.route}`
                                                ? item.activeIcon
                                                : item.icon
                                        }
                                    />
                                    {item.title && <div className="title">{item.title}</div>}
                                </button>
                            </Link>
                        </Tooltip>
                    ))}
                    {invisibleMenuItems.length > 0 &&
                        <Dropdown
                            menu={{
                                items: invisibleMenuItems.map((item, index) =>
                                ({
                                    label: <Tooltip placement="top" title={item.info}>
                                                <Link onClick={handleBtnClick} to={`/${item.route}`} style={{ color: location.pathname === `/${item.route}` ? "blue" : '' }}>
                                                    {item.title}
                                                </Link>
                                            </Tooltip>,
                                    key: index,
                                }))
                            }}>
                            <button className={`custom-button ${accessibleMenu.slice(4,).some(item => `/${item.route}` === `${location.pathname}`) ? 'active' : ''}`}>
                                <img
                                    width="22"
                                    height="22"
                                    src={MenuIcon}
                                />
                            </button>
                        </Dropdown>
                    }
                </div>

            </header>

            <Modal
                title="Are you sure you want to logout?"
                open={open}
                onCancel={() => setOpen(false)}
                footer={[
                    <Button key="okay" type="text" onClick={() => setOpen(false)}>
                        Cancel
                    </Button>,
                    <Button key="okay" type="primary" onClick={handleLogout}>
                        Okay
                    </Button>,
                ]}
            >

            </Modal>
        </>
    )
}

export default CustomHeader;