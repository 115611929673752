import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "../libs/utilities/helpers/Auth";
import setBaseURL from "../libs/utilities/helpers/BaseURLHelper";
import { api } from "./config/axiosConfig";

const DEV_BASE_URL = process.env.REACT_APP_BASE_URL_CHAT_AI;

export const getOpenChatResponse = createAsyncThunk(
    "/open/chat/",
    async (data, { rejectWithValue }) => {
        try {
            const user = Auth.getUser();
            setBaseURL(api, DEV_BASE_URL)
            const response = await api.post("/open/chat/", data, {
                headers: {
                    conversation_id: sessionStorage.getItem('conversation_id') != null ? sessionStorage.getItem('conversation_id') :
                        user.Txnid
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const getUserChatHistory = createAsyncThunk(
    "/open/chat/history/user",
    async (data, { rejectWithValue }) => {
        try {
            const user = Auth.getUser();
            setBaseURL(api, DEV_BASE_URL)
            const response = await api.post("/open/chat/history/user", data, {
                headers: {
                    conversation_id: sessionStorage.getItem('conversation_id') != null ? sessionStorage.getItem('conversation_id') :
                        user.Txnid
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const getChatHistoryById = createAsyncThunk(
    "/open/chat/history",
    async (data, { rejectWithValue }) => {
        try {
            const user = Auth.getUser();
            setBaseURL(api, DEV_BASE_URL)
            const response = await api.post("/open/chat/history", data, {
                headers: {
                    conversation_id: sessionStorage.getItem('conversation_id') != null ? sessionStorage.getItem('conversation_id') :
                        user.Txnid
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)


export const deleteChatHistoryById = createAsyncThunk(
    "/open/chat/delete",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL)
            const response = await api.post("/open/chat/delete", data, {
                headers: {
                    conversation_id: data?.conversation_id
                },
            });
            return response;
            // return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)


export const getPromptLibrary = createAsyncThunk(
    "/open/chat/prompt/library",
    async (data, { rejectWithValue }) => {
        try {
            if(data === 'process-buddy') {
                setBaseURL(api, process.env.REACT_APP_BASE_URL_PROCESS_GURU)
                const response = await api.get("/process/guru/prompt/library");
                return response.data;
            } else {
                setBaseURL(api, DEV_BASE_URL)
                const response = await api.get("/open/chat/prompt/library");
                return response.data;
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const fetchPromptLibrary = {

}
