import React, { useState, useRef, useEffect } from 'react';
import { Button, Card, message, Spin, Modal, Input, Radio, Tooltip } from 'antd';
import { LikeOutlined, DislikeOutlined, LikeTwoTone, DislikeTwoTone, FilePdfOutlined, RedoOutlined, StarOutlined } from '@ant-design/icons';
import "../../assets/styles/bot2.scss";
import ChatAnimationImage from "../../assets/images/icons/chat_circle.gif";
import UserIcon from "../../assets/images/27470347_7309691 1.svg";
import BotIcon from "../../assets/images/1100838311-preview 1.svg";
import { generateAnswer, getPdf, saveFeedback } from '../../apis/ProcessGuruAPI';
import { useDispatch } from 'react-redux';
import Scrollbar from "perfect-scrollbar";
import { v4 as uuidv4 } from 'uuid';
import HandAnimatedIcon from "../../assets/images/icons/animated_hand.gif";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import ReactQuill from 'react-quill';
import PDFViewer from '../../components/PDFViewer';
import { search } from 'fast-fuzzy';
import { Auth } from "../../libs/utilities/helpers/Auth";
import processnames from './processNames.json';

const { TextArea } = Input;

const compulsoryFeedbackUsers = [
  '162395', // Neha Solani
  '220121', // P S Sumathi
  '180429', // Nithun Nair
  '129429', // Rizwan Mohammed
  '180194', //Manjiri Gandhi
  '214550', //Nagendra
];
const compulsoryFeedbackDepartments = ['Process Excellence'];
const Chat = ({ prompt, folderList, showTour }) => {

  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const chatContentRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfData, setPDFDate] = useState({ blob: "", page: 0 });
  const [pdfLoading, setPDFLoading] = useState(false);
  const [selectedPDF, setSelectedPDF] = useState('');
  const [totalFileCount, setTotalFileCount] = useState(0);
  const quillRef = useRef(null);
  const [feedback, setFeedback] = useState({});
  const [highlightedFeedback, setHighlightedFeedback] = useState({});
  const [dislikeModal, setDislikeModal] = useState({ visible: false, messageId: null, starRating: null });
  const [dislikeFeedback, setDislikeFeedback] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const dislikeInputRef = useRef(null);
  const [welcomeModalVisible, setWelcomeModalVisible] = useState(true);
  const suggestionTimeoutRef = useRef(null);
  const [feedbackRequired, setFeedbackRequired] = useState(false);
  let showUserInput = true;
  const loggedInUser = JSON.parse(localStorage.getItem('AuthUser'));
  const [feedbackGiven, setFeedbackGiven] = useState({});
  const [ratings, setRatings] = useState({});


  useEffect(() => {
    let ps = new Scrollbar(chatContentRef.current);
    return () => {
      if (ps) {
        ps.destroy();
        ps = null;
      }
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      Auth.setTxnId(uuidv4());
    };
    //handell when user refresh page 
    window.addEventListener('beforeunload', handleBeforeUnload);
    //remove the event
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Function to check if logged-in user is in the compulsory feedback list
  const isCompulsoryFeedbackUser = () => {
    return compulsoryFeedbackUsers.includes(loggedInUser?.Username) || compulsoryFeedbackDepartments.includes(loggedInUser?.Department);
  };

  const handleRefresh = () => {
    setChatHistory([]);
    setUserInput('');
    setIsWaiting(false);
    setSuggestions([]);
    setPDFDate({ blob: "", page: 0 });
    setSelectedPDF('');
    Auth.setTxnId(uuidv4());
    setFeedbackGiven({});
    setFeedbackRequired(false);

    // Scroll to top of the chat content
    if (chatContentRef.current) {
      chatContentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",  // Smooth scrolling to the top
      });
    }

    // Show success message
    message.success('Chat cleared.');
  };

  useEffect(() => {
    let totalCount = 0;
    folderList.forEach(item => {
      totalCount += item.file.length;
    });
    setTotalFileCount(totalCount);
  }, [folderList]);

  useEffect(() => {
    setUserInput(prompt);
  }, [prompt]);

  const handleChatSubmit = async (selectedMessage) => {
    const lastMessageId = chatHistory[chatHistory.length - 1]?.messageId;
  
    // Check if feedback is required and hasn't been given for the last query
    if (lastMessageId && !feedbackGiven[lastMessageId]) {
      message.error("Please provide feedback for the previous query before submitting a new one.");
      return;
    }
  
    // Proceed with submitting the new query if feedback is provided or not required
    const strippedText = selectedMessage.trim();
    const cleanedInput = new DOMParser().parseFromString(strippedText, 'text/html').body.textContent.trim();
    const HTML_TAG_REGEX = /<[^>]+>/;
    if (!cleanedInput || cleanedInput.length > 300 || HTML_TAG_REGEX.test(cleanedInput)) {
      message.destroy();
      if (!cleanedInput) {
        message.error("Prompt cannot be empty.");
      } else if (cleanedInput.length > 300) {
        message.error("The prompt must be limited to a maximum of 300 characters.")
      } else if (HTML_TAG_REGEX.test(cleanedInput)) {
        message.error("Please enter a valid prompt without special characters");
      }
      return;
    }
  
  
    setIsWaiting(true);
    setUserInput('');
    let uuid = uuidv4();
    let queryData = {
      type: 'user',
      uuid: uuid,
      query: cleanedInput,
      response: null,
      meta: [],
      isLoading: true,
      content: cleanedInput,
      responseType: "success"
    };
    setChatHistory((prevMessages) => [
      ...prevMessages,
      queryData
    ]);
    scollChatToBottom();

    const requestBody = {
      query: cleanedInput,
      query_id: uuid,
    };

    try {
      const response = await dispatch(generateAnswer(requestBody));
      if (response.error) {
        let errorMessage;
        if (response.payload.statusCode == 'ERR0044')
          errorMessage = 'Please enter a valid prompt without special characters';
        let query_id = response.meta.arg.query_id;
        setChatHistory((previousHistory) => {
          let updatedHistory = previousHistory;
          const targetIndex = updatedHistory.findIndex(item => item.uuid === query_id);
          updatedHistory[targetIndex].response = { answer: errorMessage || "Something went wrong!" };
          updatedHistory[targetIndex].meta = [];
          updatedHistory[targetIndex].isLoading = false;
          updatedHistory[targetIndex].responseType = "error";
          updatedHistory[targetIndex].messageId = null;
          return [...updatedHistory];
        });
      } else {
        setChatHistory((previousHistory) => {
          let updatedHistory = previousHistory;
          const targetIndex = updatedHistory.findIndex(item => item.uuid === response.meta.arg.query_id);
          updatedHistory[targetIndex].response = response.payload.data;
          updatedHistory[targetIndex].meta = response.payload.data.meta.map(item => ({ fileId: item.file_id, name: getFileName(item.file_id), page: item.page }));
          updatedHistory[targetIndex].isLoading = false;
          updatedHistory[targetIndex].responseType = "success";
          updatedHistory[targetIndex].messageId = response.payload.data.messageId;
          return [...updatedHistory];
        });

        // If the user is in the compulsory feedback list, mark feedback as required
        if (isCompulsoryFeedbackUser()) {
          setFeedbackRequired(true);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsWaiting(false);
      scollChatToBottom();
    }
  };

  const scollChatToBottom = () => {
    setTimeout(() => {
      if (chatContentRef.current) {
        chatContentRef.current.scrollTo({
          top: chatContentRef.current.scrollHeight,
          behaviour: "smooth"
        });
      }
    }, 500);
  };

  const handleStarClick = (starRating, messageId) => {
    setRatings((prev) => ({ ...prev, [messageId]: starRating }));

    // If rating is 1, 2, or 3, show the modal
    if (starRating <= 3) {
      // setIsModalOpen(true);
      handleFeedback(messageId, 'dislike', starRating)
    }
    // If rating is 4 or 5, make an API call
    else if (starRating >= 4) {
      // callApi(starRating);
      handleFeedback(messageId, 'like', starRating)
    }
  };

  const handleKeyPress = (event) => {

    // If only Enter is pressed, call your function
    // yourineFunction();
    const HTML_TAG_REGEX = /<[^>]+>/;
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        // If Shift + Enter is pressed, insert a newline
        insertNewLine();
      } else {
        const cleanedInput = new DOMParser().parseFromString(userInput, 'text/html').body.textContent.trim();
        console.log("text.." + cleanedInput);
        if (cleanedInput && cleanedInput.length <= 300 && !HTML_TAG_REGEX.test(cleanedInput)) {
          handleChatSubmit(cleanedInput);
        } else {
          message.destroy();
          setUserInput('')
          if (!cleanedInput) {
            message.error("Prompt cannot be empty.");
          } else if (HTML_TAG_REGEX.test(cleanedInput)) {
            message.error("Please enter a valid prompt without special characters");
          } else {
            message.error("The prompt must be limited to a maximum of 300 characters.");
          }
        }
      }
      // Prevent default behavior to avoid Quill's default newline behavior
      event.preventDefault();
    }
  };

  const insertNewLine = () => {
    const quill = quillRef.current.getEditor();
    const selection = quill.getSelection();
  };

  const downloadPDF = async (name, fileId, page) => {
    setSelectedPDF(fileId);
    const folder = folderList.find(item => item.file.find(file => file.id === fileId));
    const file = folder.file.find(item => item.id === fileId);

    const data = {
      folderName: folder.folder_name,
      fileName: file.name,
    };
    setPDFLoading(true);
    const response = await dispatch(getPdf(data));

    if (!response.error) {
      const binaryString = window.atob(response.payload.data);

      const binary = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        binary[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([binary], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const pdfViewerUrl = `${url}#page=${page}`;
      window.open(pdfViewerUrl, "_blank")
      if (blob) {
        setPDFLoading(false);
        // setPDFDate({ name, blob, page });
        // setIsModalOpen(true);
      }
    } else {
      setPDFLoading(false);
    }
  };

  const getFileName = (fileId) => {
    const file = folderList?.find(item => item.file?.find(file => file?.id === fileId))?.file?.find(item => item.id === fileId);
    return file?.name;
  };

  const handleInputChange = async (value) => {
    if (showUserInput) {
      // setUserInput(quillRef.current.input.value);

      // // Extract the last word from the input value
      // const strippedText = quillRef.current.input.value;

      setUserInput(value);
      // setUserInput(quillRef.current.input.value);
      const strippedText = new DOMParser().parseFromString(value, 'text/html').body.textContent.trim().toLowerCase();
      // Extract the last word from the input value
      // const strippedText = quillRef.current.input.value;

      // Split input into words
      const words = strippedText.split(/\s+/);
      // let leastWordMatchCount;
      // let leastWordMatches = [];

      const stopWord = ['what', 'when', 'who', 'whom', 'why', 'how', 'is', 'am', 'are', 'was', 'were', 'will', 'shall', 'can', 'may', 'could', 'would', 'should', 'might', 'at', 'on', 'of', 'in', 'for', 'from', 'above', 'below', 'the', 'you', 'applicable', '?', '!', '.'];

      // Iterate over words and perform fuzzy search
      // words.forEach((word) => {
      //   const normalizedWord = word.toLowerCase();
      //   if (!stopWord.includes(normalizedWord)) {
      //     const matches = search(normalizedWord, processnames)
      //     // Update least matches if found
      //     if ((leastWordMatchCount == undefined && matches.length !== 0) || (leastWordMatchCount > matches.length && matches.length !== 0)) {
      //       leastWordMatchCount = matches.length;
      //       leastWordMatches = matches;
      //     }
      //   }
      // });
      let wordMatchFlag = false;
      let matches = [];
      for (let i = words.length - 1; i >= 0; i--) {
        const normalizedWord = words[i].toLowerCase();
        if (!stopWord.includes(normalizedWord) && !wordMatchFlag) {
          matches = search(normalizedWord, processnames);
          wordMatchFlag = true;
          // console.log(wordMatchFlag + "  " + matches.length + " " + normalizedWord);
        }
      }

      setSuggestions(matches);

      // If suggestions are shown, set a timeout to hide them after 6 seconds
      if (matches.length > 0) {
        // Clear any existing timeout
        if (suggestionTimeoutRef.current) {
          clearTimeout(suggestionTimeoutRef.current);
        }

        // Set a new timeout to hide suggestions after 6 seconds
        suggestionTimeoutRef.current = setTimeout(() => {
          setSuggestions([]);  // Clear the suggestions after 6 seconds
        }, 5000);
      }

    } else {
      showUserInput = true;
    }
  };

  const handleSuggestionClick = (suggestion) => {
    // Set the userInput to the selected suggestion
    var inputText = new DOMParser().parseFromString(userInput, 'text/html').body.textContent.trim().toLowerCase();
    inputText = inputText.substring(0, inputText.lastIndexOf(' '));
    setUserInput(inputText + " " + suggestion);
    setSuggestions([]);
    showUserInput = false;
    quillRef.current.focus();
    // Hide suggestions after 6 seconds
    setTimeout(() => {
      setSuggestions([]);
    }, 6000);
  };

  const handleFeedback = async (messageId, feedbackType, starRating) => {
    setHighlightedFeedback({ [messageId]: feedbackType });

    if (feedbackType === 'dislike') {
      // Open the dislike modal when the user clicks dislike
      setDislikeModal({ visible: true, messageId, starRating });
    } else {
      // Process 'like' feedback
      message.success('Thanks for your feedback!', 3);
      setHighlightedFeedback({});
      const feedbackData = {
        messageId: messageId,
        feedbackType: starRating,
        // feedback: feedbackMessage,
      };
      // Save the feedback (like)
      await dispatch(saveFeedback(feedbackData));

      // Mark feedback as given for this query and hide feedback options
      setFeedbackGiven(prev => ({ ...prev, [messageId]: true }));  // Update feedbackGiven state
      // setRating(0);
    }
  };



  const handleDislikeInputChange = (e) => {
    setDislikeFeedback(e.target.value);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleModalClose = () => {
    setDislikeModal({ visible: false, messageId: null });
    setDislikeFeedback('');
    setSelectedOption(null);
  };


  const handleModalSubmit = async () => {
    // Ensure a radio button is selected before submitting
    if (!selectedOption) {
      message.error('Please select a reason for your dislike.');
      return;
    }

    let feedbackMessage = selectedOption;
    if (dislikeFeedback) {
      feedbackMessage += `. ${dislikeFeedback}`;
    }

    const feedbackData = {
      messageId: dislikeModal.messageId,
      feedbackType: dislikeModal.starRating,
      feedback: feedbackMessage,
    };

    // Dispatch feedback to the backend
    await dispatch(saveFeedback(feedbackData));

    // Reset modal state
    setDislikeModal({ visible: false, messageId: null });
    setDislikeFeedback('');
    setSelectedOption(null);

    // Show success message
    message.success('Thanks for your feedback!', 3);

    // Mark feedback as given for the current query and hide feedback options
    setFeedbackGiven(prev => ({ ...prev, [dislikeModal.messageId]: true }));  // Update feedbackGiven state
    // setRating(0);
  };

  useEffect(() => {
    if (dislikeModal.visible && dislikeInputRef.current) {
      dislikeInputRef.current.focus();
    }
  }, [dislikeModal.visible]);
  useEffect(() => {
  }, [userInput])
  return (
    <Card className="chatbot-card" size="large" style={{ border: 0 }}>
      <PDFViewer pdfData={pdfData} show={isModalOpen} handleClose={() => setIsModalOpen(false)} />
      <>
        {chatHistory.length === 0 ? (
          <div className="chatbot-content" ref={chatContentRef} style={{ justifyContent: "center" }}>
            <img className='animation' src={ChatAnimationImage} alt="waiting-gif" width={100} height={100} />
            <p className='text5'>
              Hello, I'm here to help with process related queries
            </p>

            <p className='text7'>
              Such as business rules, process summary, activities etc.You can use pre-drafted questions from the prompt library or ask your own questions like <b class="text8">What is the objective of Surrender Process via V-Serve?</b> For better response, please mention the process name.</p>

          </div>
        ) :

          <div className="chatbot-content" ref={chatContentRef}>
            {chatHistory.length > 0 && chatHistory.map((item, index) => (
              <div key={index} style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                <div className={`chat-message user`}>
                  <span className='user-message success'>{item.query}</span>
                  <img className='user-icon' src={UserIcon} alt="user-icon" width={40} height={40} />
                </div>
                <div className={`chat-message bot`}>
                  <img className='bot-icon' src={BotIcon} alt="bot-icon" width={40} height={40} />
                  {item.isLoading ? <Spin size="small" style={{ marginLeft: 8 }} /> :
                    <div className={`bot-message ${item.responseType}`}>
                      <div
                        style={{ whiteSpace: "pre-line" }}
                        dangerouslySetInnerHTML={{
                          __html: item.response.answer
                            .replace(/\n/g, '<br/>')             // Replaces \n with line break
                            .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;') // Replaces \t with four spaces
                            .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')  // Replaces **text** with <b>text</b>
                            .replace(/\*(.*?)\*/g, '<i>$1</i>')   // Replaces *text* with <i>text</i>
                        }}
                      />
                      {item.response.meta &&
                        <>
                          {item.meta.length > 0 && <div style={{ color: "gray", fontSize: "12px", borderTop: "1px solid gray", paddingTop: "4px", marginTop: "4px" }}>
                            This response is generated by Gen AI. Please verify the information by clicking on the given pdf link.
                          </div>}

                          <div style={{ display: "flex", flexDirection: "row", gap: "7px", overflowY: "auto", paddingTop: "0px", justifyContent: "space-between" }}>
                            <div style={{display:"flex",flexDirection:"column"}}>
                              {item.meta.map((file, index) => (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                  <div key={index} style={{ display: "flex", flexDirection: "column", gap: "5px", fontSize: "12px", color: "black", display: "flex", gap: 2, paddingTop: "3px" }}>
                                    {/* <div style={{ display: "flex", flexDirection: "row" }}>
                                  <div style={{ display: "flex", alignItems: "flex-start", gap: "5px", cursor: "pointer" }} onClick={() => downloadPDF(file.name, file.fileId, 0)}>
                                    <FilePdfOutlined style={{ marginTop: "2px" }} />Renewal & Revival Payment Collection - Central Receipting.pdf ndbdbjdbjbhdnbdhb</div>
                                  {file.page.length > 0 && <div>
                                    - ({
                                      file.page.map((page, index) => (<span key={index} onClick={() => downloadPDF(file.name, file.fileId, page)} style={{ color: "#4b8bf2", cursor: "pointer" }}>Page no: {page}{index !== file.page.length - 1 && ","}  </span>))
                                    } )
                                  </div>}
                                  </div> */}
                                    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
                                      {/* File Name */}
                                      <div style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer" }} onClick={() => downloadPDF(file.name, file.fileId, 0)}>
                                        <FilePdfOutlined style={{ marginTop: "2px" }} />
                                        {file?.name}
                                      </div>

                                      {file.page.length > 0 && (
                                        <div >
                                          &nbsp;- (
                                          {file.page.map((page, index) => (
                                            <span key={index} onClick={() => downloadPDF(file?.name, file?.fileId, page)} style={{ color: "#4b8bf2", cursor: "pointer" }}>
                                              Page no: {page}{index !== file?.page.length - 1 && ","}
                                            </span>
                                          ))}
                                         )
                                        </div>
                                      )}
                                    </div>
                                    {(selectedPDF === file?.fileId) && pdfLoading && <span>Loading...</span>}
                                  </div>
                                </div>
                              ))}
                              </div>
                            {/* Conditionally render feedback icons based on response type */}
                            {item.responseType !== 'error' && (
                              <div className="star-rating" style={{ display: 'flex', gap: '2px', float: "right", marginRight: "10px", alignItems: "end" }}>
                                {[1, 2, 3, 4, 5].map((star) => (
                                  <span
                                    key={star}
                                    onClick={() => feedbackGiven[item.messageId] ? null : handleStarClick(star, item.messageId)}
                                    className={`star ${ratings[item.messageId] >= star ? "filled" : ""}`}
                                    style={{ cursor: feedbackGiven[item.messageId] ? "not-allowed" : "pointer" }} // Disable cursor when feedback is submitted
                                  >
                                    <StarOutlined />
                                    {/* ★ */}
                                  </span>
                                ))}
                              </div>
                            )}
                          </div>
                        </>
                      }
                    </div>}
                </div>
              </div>
            ))}
          </div>
        }
      </>
      <div className="chat-input" style={{ flexDirection: "column" }}>
        <div className='chat-input-content'>
          <div className="chat-input-container" style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
            <ReactQuill
              placeholder="Type your prompt..."
              ref={quillRef}
              className="quill-prompt"
              theme="bubble"
              modules={{ toolbar: false }}
              value={userInput}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
            />

            <div
              style={{
                position: 'absolute',
                right: '42px', // Positioned just before the button
                top: '50%',
                transform: 'translateY(-50%)',
                width: '1px',
                height: '80%', // Adjust the height as needed
                backgroundColor: '#d9d9d9',
              }}
            ></div>
            <Tooltip title="Clear Chat">
              <Button
                className="refresh-button"
                type="default"
                onClick={handleRefresh}
                style={{
                  position: 'absolute',
                  right: '-2px',
                  top: '6%',
                  backgroundColor: "transparent"
                }}
              >
                <RedoOutlined style={{ fontSize: '18px' }} />
              </Button>
            </Tooltip>
            {suggestions.length > 0 && userInput && (
              <div className="suggestions-list">
                {suggestions.map((suggestion, index) => (
                  <div key={index} onClick={() => handleSuggestionClick(suggestion)}>
                    {suggestion}
                  </div>
                ))}
              </div>
            )}
          </div>

          <Button
            className="chat-input-button"
            type="primary"
            onClick={() => handleChatSubmit(userInput)} // Always clickable
            // disabled={isWaiting || userInput.length === 0 || userInput.trim() === ""}
            disabled={isWaiting || userInput.length == 0 || userInput === "<p><br></p>"}
          >
            Submit
          </Button>

        </div>
        <div style={{ textAlign: "center", width: "100%", fontSize: "12px", paddingRight: "50px", marginTop: "3px" }}>
          Process Buddy is prone to factual inaccuracies; It is advisable to verify the information.
        </div>
        {showTour && <div className="hand-animated" style={{ position: "absolute", zIndex: "10000", bottom: "-20px" }}>
          <img src={HandAnimatedIcon} width={80} />
        </div>}
      </div>

      <Modal
        title="Tell us more, why did you dislike?"
        visible={dislikeModal.visible}
        onCancel={handleModalClose}
        onOk={handleModalSubmit}
        okText="Submit"
        closable={false}             // Removes the close icon
        maskClosable={false}         // Prevents closing when clicking outside the modal
        okButtonProps={{
          disabled: !selectedOption  // Enable the submit button only if a radio button is selected
        }}
      >
        <Radio.Group onChange={handleOptionChange} value={selectedOption}>
          <Radio value="Relevant and exhaustive">Relevant but not exhaustive</Radio>
          <Radio value="Relevant but not exhaustive">Slightly irrelevant </Radio>
          <Radio value="Irrelevant response">Completely irrelevant</Radio>
        </Radio.Group>
        <TextArea
          ref={dislikeInputRef}
          placeholder="Please provide additional comments (optional)"
          value={dislikeFeedback}
          onChange={handleDislikeInputChange}
          autoFocus
          style={{
            height: "6rem",
            borderRadius: "5px",
            padding: "0.5rem",
            fontSize: "14px",
            boxSizing: "border-box",
            lineHeight: "1.25",
            verticalAlign: "top",
            display: "block",
            width: "100%",
            resize: "none"
          }}
          maxLength={1000}
        />
      </Modal>

    </Card>
  );
};

export default Chat;
