import { useEffect, useState } from "react";
import LongArrowImage from '../../assets/images/icons/big_arrow.svg';
import BotIcon from "../../assets/images/1100838311-preview 1.svg";
import "./TourChatAI.scss"

const TourChatAI = ({ show, handleShow, page }) => {

    const [showIntro, setShowIntro] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const input = document.getElementsByClassName('quill-prompt-container');
    const button = document.getElementsByClassName('chat-input-button');


    useEffect(() => {
        let timeoutRef;

        if (!show) {
            input[0].style.zIndex = "inherit";
            button[0].style.zIndex = "inherit";
            setIsShow(show);
            timeoutRef = setTimeout(() => {
                setShowIntro(false);
                clearTimeout(timeoutRef)
            }, 500)
        } else {
            input[0].style.zIndex = 9999;
            button[0].style.zIndex = 9999;
            setShowIntro(true);
            setTimeout(() => {
                setIsShow(show);
                clearTimeout(timeoutRef)
            }, [500])
        }
        return () => {
            clearTimeout(timeoutRef)
        }
    }, [show])


    return (
        <>
            { showIntro &&
                <div className={`intro-overlay ${isShow ? 'show' : ''}`}>
                    <div className="intro-overlay-content">
                        <div className="left">
                            <div className="content">
                                <div className="prompt-text">
                                    Start Typing Your Prompt To See The Magic
              </div>
                                <button className="btn-got-it" onClick={handleShow}>I GOT IT</button>
                                <div className="arrow-image">
                                    <img src={LongArrowImage} />
                                </div>
                            </div>
                        </div>
                        <div className="middle">
                            <div className="intro-card">
                                <div className="top">
                                    <div className="icon">
                                        <img src={BotIcon} width={70} />
                                    </div>
                                    <div className="name">
                                        "Hello, I AM GALAXY"
                                    </div>
                                </div>
                                <div className="bottom">
                                    <div className="prompt">"PROMPT EXAMPLE"</div>
                                    <div className="example">
                                        Tell me about HDFC Life
                                    </div>
                                    <div className="description">
                                        <div className="description-content">
                                            HDFC Life is a leading general insurance company in India. It offers a wide range of insurance products including health, life, and general insurance. HDFC Life is known for its customer-centric approach and has a strong brand presence in India.
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="last">
                        </div>
                    </div>
                </div>}
        </>)
}

export default TourChatAI;