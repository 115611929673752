import { createSlice } from "@reduxjs/toolkit";

const infoDialogSlice = createSlice({
    name: 'infoDialogSlice',
    initialState: {
        show: false,
        title: "",
        description: ""
    },
    reducers: {
        showInfoDialog: (state, action) => {
            state.show = true;
            state.title = action.payload.title;
            state.description = action.payload.description;
        },
        hideInfoDialog: (state, action) => {
            state.show = false;
            state.title = "";
            state.description = "";
        }
    }
})

export const { showInfoDialog, hideInfoDialog } = infoDialogSlice.actions;

export default infoDialogSlice.reducer