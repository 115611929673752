import { createSlice } from "@reduxjs/toolkit";

const sessionDialogSlice = createSlice({
    name: 'sessionDialog',
    initialState: {
        show: false,
    },
    reducers: {
        toggleSessionExpireDialog: (state, action) => {
            state.show = action.payload.show;
        },
    }
})

export const { toggleSessionExpireDialog }  = sessionDialogSlice.actions;

export default sessionDialogSlice.reducer