import { Select, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import "./PromptLibrary.scss";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { getPromptLibrary } from "../../apis/ChatAIAPI";
import ArrowUpIcon from "../../assets/images/icons/arrow_up.svg";

const PromptLibrary = ({ showPrompt, getPrompt, page }) => {
    const dispatch = useDispatch();
    const promptLibrary = useSelector((state) => state.prompt);
    const [showScroll, setShowScroll] = useState(false);
    const [hasScrolledPastThreshold, setHasScrolledPastThreshold] = useState(false);
    const promptRef = useRef(null);
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [prompts, setPrompts] = useState([]);

    // Fetch Prompt Library on mount
    useEffect(() => {
        dispatch(getPromptLibrary(page));
    }, [page, dispatch]);

    // Update categories and subcategories when promptLibrary data is ready
    useEffect(() => {
        if (promptLibrary.status === 'succeeded' && promptLibrary.prompts.length > 0) {
            const categories = [
                { value: 'all', label: 'All' },
                ...promptLibrary.prompts.map(item => ({ value: item.category, label: item.category }))
            ];
            setCategories(categories);
            setCategory(categories[0].value); // Set default to 'All'
            const subCategories = promptLibrary.prompts.find(item => item.category === categories[0].value)?.sub_category || [];
            const mappedSubCategories = [{ value: 'all', label: 'All' }, ...subCategories.map(item => ({ value: item.name, label: item.name }))];
            setSubCategories(mappedSubCategories);
            setSubCategory(mappedSubCategories[0].value); // Set default to 'All'
            getPrompts('category', categories[0].value, mappedSubCategories[0].value);
        }
    }, [promptLibrary.status, promptLibrary.prompts]);

    // Handle scroll logic
    useEffect(() => {
        const handleScroll = () => {
            const target = promptRef.current;
            if (!target) return;
            if (target.scrollTop > 100 && !hasScrolledPastThreshold) {
                setShowScroll(true);
                setHasScrolledPastThreshold(true);
            } else if (target.scrollTop <= 100) {
                setHasScrolledPastThreshold(false);
                setShowScroll(false);
            }
        };

        const target = promptRef.current;
        if (target) {
            target.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (target) {
                target.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasScrolledPastThreshold]);

    // Function to retrieve prompts based on category or subCategory
    const getPrompts = (type, category, subCategory) => {
        let prompts = [];
        if (category === 'all') {
            // If "All" category is selected, get all prompts from all categories
            prompts = promptLibrary.prompts.flatMap(cat =>
                cat.sub_category.flatMap(sub => sub.prompts)
            );
        } else {
            const selectedCategory = promptLibrary.prompts.find(item => item.category === category);
            if (selectedCategory) {
                if (subCategory === 'all') {
                    // If "All" subcategory is selected, get all prompts from the selected category
                    prompts = selectedCategory.sub_category.flatMap(sub => sub.prompts);
                } else {
                    // Get prompts from the selected subcategory
                    const selectedSubCategory = selectedCategory.sub_category.find(item => item.name === subCategory);
                    prompts = selectedSubCategory ? selectedSubCategory.prompts : [];
                }
            }
        }

        const modifiedData = prompts.map(item => ({
            query: item.query
                .replace(/\[/g, '<span style="color: #22a0c9; font-weight: 600; padding: 0px 3px; width: fit-content; border-radius: 3px">')
                .replace(/\]/g, '</span> ')
        }));
        setPrompts(modifiedData);
    };

    const handleSetCategory = (value) => {
        setCategory(value);
        const subCategories = value === 'all'
            ? []
            : promptLibrary.prompts.find(item => item.category === value)?.sub_category || [];

        const mapped = [{ value: 'all', label: 'All' }, ...subCategories.map(item => ({ value: item.name, label: item.name }))];
        setSubCategories(mapped);
        setSubCategory(mapped[0]?.value || 'all'); // Default to 'All'
        getPrompts('category', value, 'all'); // Fetch prompts when category changes
    };

    const handleSetSubCategory = (value) => {
        setSubCategory(value);
        getPrompts('subCategory', category, value);
    };


    // Scroll to the top function
    const scrollToTop = () => {
        const target = promptRef.current;
        if (target) {
            target.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    };

    return (
        <div className="prompt-library" style={{ display: showPrompt ? "block" : "none" }}>
            <div className="prompt-categories">
                <div className="item">
                    <div className="label">Select Category</div>
                    {categories.length > 0 && <Select className="dropdown-item" size="large" style={{ width: "100%" }} value={category} onChange={handleSetCategory} options={categories} />}
                </div>
                <div className="item">
                    <div className="label">Select Sub-Category</div>
                    {subCategories.length > 0 && <Select className="dropdown-item" size="large" style={{ width: "100%" }} value={subCategory} onChange={handleSetSubCategory} options={subCategories} />}
                </div>
            </div>
            <div className="prompt-cards" ref={promptRef}>
                {prompts.map((item, index) => (
                    <div className="prompt-card" key={index}>

                        <div className="prompt" dangerouslySetInnerHTML={{ __html: item.query }}></div>

                        {/* <div className="try-out" onClick={() => getPrompt({ ...item, query: item.query.replace(/<\/?[^>]+(>|$)/g, "") })}>
                            Try Out
                        </div> */}

                        <div className="try-out" onClick={() => getPrompt({
                            ...item, query: item.query.replace(/\[/g, '<span style="color: #22a0c9; font-weight: 600; padding: 0px 3px; width: fit-content; border-radius: 3px">')
                                .replace(/\]/g, '</span> ', "")
                        })}>
                            Try Out
                        </div>
                    </div>
                ))}

                {showScroll && (
                    <div className="scrollTop" onClick={scrollToTop}>
                        <Tooltip title="Scroll To Top"><img width={40} src={ArrowUpIcon} alt="Scroll to top" /></Tooltip>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PromptLibrary;
