import { createSlice } from "@reduxjs/toolkit";

const tourSlice = createSlice({
    name: 'tour',
    initialState: {
        show: false,
        page: "",
    },
    reducers: {
        toggleTour: (state, action) => {
            state.show = action.payload.show;
        },
    }
})

export const { toggleTour }  = tourSlice.actions;

export default tourSlice.reducer