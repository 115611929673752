import { createSlice } from "@reduxjs/toolkit";
import { getUserChatHistory } from "../apis/ChatAIAPI";

const openChatSlice = createSlice({
    name: "openChat",
    initialState: {
        chatHistory: [],
        chatHistoryLoading: false,
        chatError: null, 
        currentChat: [],
        currentChatHistory:[],
        responseReceived: false, // Add new state to track response

    },
    reducers: {
        resetCurrentChat: (state) => {
            state.currentChat = [];
        },
        setCurrentChat: (state, action) => {
            state.currentChat = action.payload;
        },
        setCurrentChatHistory: (state, action) => {
            state.currentChatHistory = action.payload;
        },
        toggleResponseReceived: (state) => {
            state.responseReceived = !state.responseReceived;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserChatHistory.pending, (state) => {
                state.chatHistoryLoading = true;
                // state.chatHistory = state.chatHistory;
                state.chatError = null;
            })
            .addCase(getUserChatHistory.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.chatHistoryLoading = false;
                state.chatHistory = data;
                state.chatError = null;
            })
            .addCase(getUserChatHistory.rejected, (state, action) => {
                state.chatHistoryLoading = false;
                state.chatHistory = [];
                state.chatError = action.payload;
            });
    },
});

export const { resetCurrentChat, setCurrentChat,setCurrentChatHistory,toggleResponseReceived } = openChatSlice.actions;
export default openChatSlice.reducer;
