import { Navigate, useNavigate } from "react-router";
import { appMenu } from "../components/CustomHeader";
import { Auth } from "../libs/utilities/helpers/Auth";

const DefaultRoute = () => {
    const user = Auth.getUser();

    let accessibility = user.accessibility.filter(item => item.status);
        if(accessibility) {
            const menus = appMenu.filter(item => accessibility.some(menu => menu.service === item.route));

            menus.sort((a, b) => {
                const priorityA = accessibility.find(item => item.service === a.route);
                const priorityB = accessibility.find(item => item.service === b.route);
                if (priorityA && priorityB) {
                    return priorityA.index - priorityB.index;
                } else if (priorityA) {
                    return -1;
                } else if (priorityB) {
                    return 1;
                }
                return 0;
            });
            return <Navigate to={menus[0].route} />
        }

        return <Navigate to="/login" />
}

export default DefaultRoute;