import { createSlice } from "@reduxjs/toolkit";
import { login } from "../apis/AuthAPI";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuthenticated: false,
        authLoading: false,
        authError: null,
        userData: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.authLoading = true;
                state.userData = null;
                state.authError = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.authLoading = false;
                state.userData = data;
                state.authError = null;
                state.isAuthenticated = true;
            })
            .addCase(login.rejected, (state, action) => {
                state.authLoading = false;
                state.userData = null;
                state.authError = action.payload;
            });
    },
});

export default authSlice.reducer;
