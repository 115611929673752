import "../assets/styles/sidebar.scss"
import React, { useEffect, useRef, useState } from "react";
import { Card, Tooltip } from "antd";
import Logo from "../assets/images/icons/GalaxyLogo.svg";
import LogoMobile from "../assets/images/icons/GalaxyLogoMobile.png";
import HDFCLogo from "../assets/images/icons/HDFC_logo.svg";
import HandWithLightIcon from "../assets/images/icons/hand_with_light.png";
import HomeIcon from "../assets/images/icons/home.svg";
import DropdownIcondown from "../assets/images/icons/dropdowndown_icon.svg";
import CirclePlusIcon from "../assets/images/icons/circle_plus.svg";
import DeleteIcon from "../assets/images/icons/delete_icon.svg";

import { Modal } from "antd";
import { v4 as uuidv4 } from "uuid";
import { Button } from "antd";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserChatHistory, getChatHistoryById, deleteChatHistoryById } from "../apis/ChatAIAPI";
import { setCurrentChatHistory } from "../reducers/openChatSlice";
import Scrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { PlayCircleFilledOutlined } from "@mui/icons-material";
import { toggleTour } from "../reducers/tourSlice";
import { UnorderedListOutlined, HistoryOutlined } from "@ant-design/icons";
import { togglePrompt } from "../reducers/promptSlice";
import { Auth } from "../libs/utilities/helpers/Auth";

const Sidebar = ({ collapsed }) => {
  const chatLIstRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const refreshSidebar = useSelector((state) => state.openChat);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [historyChat, setHistoryChat] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [deleteConversationId, setDeleteConversationId] = useState();
  const comp = useRef();
  const user = Auth.getUser();

  const accessibility = user.accessibility.filter(item => item.status);
  const activeServices = accessibility.map(service => service.service);
  useEffect(() => {
    let ps = new Scrollbar(comp.current);
    return () => {
      if (ps) {
        ps.destroy();
        ps = null;
      }
    };
  }, []);

  const location = useLocation();


  useEffect(() => {
    if (activeServices.includes('chat-ai') && location.pathname === '/chat-ai') {
      dispatch(getUserChatHistory())
        .then((userChatResponse) => {
          setConversationHistory(userChatResponse.payload.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [refreshSidebar.responseReceived])
  const createNewChat = () => {

    dispatch(togglePrompt({ show: false }))

    let newChatId = uuidv4();
    sessionStorage.setItem("conversation_id", newChatId);
    sessionStorage.setItem("currentChatId", newChatId);

    dispatch(setCurrentChatHistory([]));
    dispatch(getUserChatHistory())
      .then((userChatResponse) => {
        setConversationHistory(userChatResponse.payload.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (activeServices.includes('chat-ai') && location.pathname === '/chat-ai') {
      dispatch(getChatHistoryById())
        .then((response) => {
          if (response?.payload?.data[0]?.conversation != undefined)
            setHistoryChat(response?.payload?.data[0]?.conversation);
          else {
            setHistoryChat([]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      dispatch(getUserChatHistory())
        .then((userChatResponse) => {
          setConversationHistory(userChatResponse.payload.data);
        })
        .catch((error) => {
          console.error(error);
        });
      // }
    }
  }, [location.pathname]);

  const getChatByID = (data) => {
    dispatch(togglePrompt({ show: false }))
    sessionStorage.setItem("currentChatId", data);
    // setCurrentChatId(data)
    sessionStorage.setItem("conversation_id", data);
    dispatch(getChatHistoryById())
      .then((response) => {
        dispatch(
          setCurrentChatHistory(response?.payload.data[0]?.conversation)
        );
        setHistoryChat(response?.payload.data[0]?.conversation);
      })
      .catch((error) => {
      });

    dispatch(getUserChatHistory())
      .then((userChatResponse) => {
        setConversationHistory(userChatResponse.payload.data);
      })
      .catch((error) => {
        console.error(error);
      });
    // console.log(response.payload)
  };

  const handelshowChat = () => {
    setShowChat((prev) => !prev);
  };
  const handelChatDelete = async () => {
    const payload = { conversation_id: deleteConversationId }
    dispatch(deleteChatHistoryById(payload)).then((res) => {

      let converstiondata = [...conversationHistory]
      converstiondata = converstiondata.filter(item => item.conversation_id !== deleteConversationId);
      setConversationHistory(converstiondata)
    }).catch((err) => {
      // console.error(err);
    })
    setDeleteOpen(false)
  }

  const ChatList = () => {
    const compchat = useRef();

    useEffect(() => {
      let ps = new Scrollbar(compchat.current);
      return () => {
        if (ps) {
          ps.destroy();
          ps = null;
        }
      };
    }, []);

    const handelDeleteClick = async (e, id) => {
      e.stopPropagation();
      setDeleteConversationId(id)
      setDeleteOpen(true);
    }

    return (
      <div className="list-chat" ref={compchat} >
        {/* <div  > */}

        {conversationHistory?.map((data, index) => (
          <>
            <div key={data.conversation_id} onClick={() => getChatByID(data.conversation_id)}
              className={sessionStorage.getItem("conversation_id") ==
                data.conversation_id
                ? "chat-history-tile highlighted"
                : "chat-history-tile"
              }
            >
              <div className="chat-history-tile-text">{sessionStorage.getItem("conversation_id") != data.conversation_id
                ? data?.conversation_title?.slice(0, 15) + "..."
                : "Current chat"}
              </div>
              <div
                className={`${sessionStorage.getItem("conversation_id") == data.conversation_id ? "chat_delete_icon-show" : ""} chat_delete_icon`}
                onClick={(e) => handelDeleteClick(e, data.conversation_id)} style={{ height: "14px" }}
              >
                <img src={DeleteIcon} width={14} alt="del" />
              </div>
            </div>

          </>
        ))}

        {/* <div key={sessionStorage.getItem('conversation_id')}
            //  onClick={() => getChatByID()}
            className={currentChatId == sessionStorage.getItem('conversation_id') ? "highlighted chat-history-tile" : "unhighlighted chat-history-tile"}
        >
            new chat
            </div> */}

        {/* </div> */}
      </div>
    )
  }

  const handleTakeATour = () => {
    dispatch(toggleTour({ show: true }))
  }


  const showPrompt = () => {
    dispatch(togglePrompt({ show: true }))
  }

  const promptSelected = useSelector((state) => state.prompt).show;


  return (
    <div className="sidebar" ref={comp} style={{ padding: !collapsed ? "32px 22px 12px 22px" : "15px 5px" }}>
      <div className="top">

        {!collapsed ?
          <div className="heading" style={{ gap: "20px" }}>
            <img src={HDFCLogo} />
            <img src={Logo} />
          </div>
          :
          <div className="heading" style={{ display: "flex", justifyContent: "center" }}>
            <img src={LogoMobile} />
          </div>
        }

        <div className="separator"></div>


        {location.pathname == "/chat-ai" ?
          collapsed ?
            <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
              <Tooltip title="Create New Instance"><img src={CirclePlusIcon} onClick={createNewChat} /></Tooltip>
            </div>
            : (
              <button
                type="primary"
                className="sidebar-button"
                onClick={createNewChat}
              >
                <div className="text">Create New Instance </div>
                <img src={CirclePlusIcon} onClick={createNewChat} />
              </button>
            ) : (
            ""
          )}

        {/* Desktop View */}
        <div className="list" style={{ display: collapsed ? "none" : "block" }}>
          <div className={`${location.pathname === '/chat-ai' ? 'active' : ''} item`} onClick={() => navigate('/chat-ai')}>
            <img src={HomeIcon} alt="Home" />
            <div className="title">Home</div>
          </div>

          {/* {location.pathname == "/chat-ai" ? ( */}
          <div >

            <div className='item sub-item chatdiv' style={{ display: location.pathname == "/chat-ai" || location.pathname === "/process-buddy" ? "block" : "none" }} >
              {/* <img src={UnorderedListOutlined} alt="Home" /> */}
              <div onClick={showPrompt} style={{ display: "flex", gap: "13px" }}>
                <UnorderedListOutlined style={{ color: promptSelected ? "blue" : "" }} />
                <div className={promptSelected ? "sub-title highlighted-chathistory" : "sub-title"}>Prompt Library</div>
              </div>
            </div>

            <div className='item sub-item chatdiv' onClick={handelshowChat} style={{ display: location.pathname == "/chat-ai" ? "flex" : "none" }}>
              <HistoryOutlined style={{ color: showChat ? "blue" : "" }} />
              <div className={showChat ? "sub-title highlighted-chathistory" : "sub-title"} style={{ flex: 1 }}>Chat History</div>
              <div className="drop_icon">
                <img src={DropdownIcondown} alt="dropdowndown" className={showChat ? "rotate" : ""} />
              </div>
            </div>
            {showChat &&
              <ChatList />}
          </div>
        </div>

        {/* Mobile View */}
        <div className="list mobileView" style={{ display: !collapsed ? "none" : "block" }}>

          <div className={`${location.pathname === '/chat-ai' ? 'active' : ''} item`} onClick={() => navigate('/chat-ai')}>
            <Tooltip title="Home"><img src={HomeIcon} alt="Home" /></Tooltip>
          </div>

          <div style={{ display: (location.pathname == "/chat-ai" || location.pathname == '/process-buddy') ? "block" : "none" }}>
            <div className='item sub-item chatdiv'>
              <div onClick={showPrompt} style={{ display: "flex", gap: "13px" }}>
                <Tooltip title="Prompt Library"><UnorderedListOutlined style={{ color: promptSelected ? "blue" : "" }} /></Tooltip>
              </div>
            </div>
          </div>

        </div>
      </div>

      {!collapsed && <div className="sidebar-bottom">
        {/* <div className="additional-links">
          <div className="item">Terms of Use</div>
          <div className="item">Privacy Policy</div>
        </div> */}
        <Card className="tour-card" onClick={() => handleTakeATour()}>
          <div className="icon">
            <img src={HandWithLightIcon} alt="lighticon" />
          </div>
          <div className="bottom">
            <div className="left">
              <div className="title">Take a Tour</div>
              <div className="description">Hi learn tips & tricks</div>
            </div>
            <div className="right" onClick={() => setOpen(true)}>
              {/* <img src={logout} alt="logout" width={25} height={25} /> */}
              <PlayCircleFilledOutlined />
            </div>
          </div>
        </Card>
        {/* <div className="footer">@ 2023 HDFC life (v2.0.5)</div> */}
        <div className="footer">©2024, HDFC Life</div>
      </div>}

      <Modal
        title="Are you sure you want to delete chat?"
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        footer={[
          <Button key="okay" type="text" onClick={() => setDeleteOpen(false)}>
            Cancel
          </Button>,
          <Button key="okay" type="primary" onClick={handelChatDelete}>
            Okay
          </Button>,
        ]}

      >
        {/* <p>{modalText}</p> */}
      </Modal>
    </div>
  );
};

export default Sidebar;
