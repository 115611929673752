import { User } from "../../classes/User";

export const Auth = {
    register: (apiUser = {}) => {
        if (apiUser.token) {
            localStorage.setItem("AuthUser", JSON.stringify(apiUser.token && new User(apiUser)));
        }
    },

    setTxnId: (txnId) => {
        let AuthUser = localStorage.getItem("AuthUser");
        AuthUser = JSON.parse(AuthUser);
        if(AuthUser && AuthUser.Txnid) {
            AuthUser.Txnid = txnId;
            localStorage.setItem("AuthUser", JSON.stringify(AuthUser))
        }
    },

    isLoggedIn: () => {
        let AuthUser = localStorage.getItem("AuthUser");
        AuthUser = JSON.parse(AuthUser);
        if (AuthUser && AuthUser.Token) {
            return true;
        } else {
            return false;
        }
    },

    deregister: () => {
        localStorage.clear();
    },

    getUser: () => {
        let AuthUser = localStorage.getItem("AuthUser");
        AuthUser = JSON.parse(AuthUser); 
        return AuthUser;
    },

    getToken: () => {
        let token = "";
        let AuthUser = localStorage.getItem("AuthUser");
        AuthUser = JSON.parse(AuthUser);
        if (!!AuthUser) {
            token = AuthUser.Token;
        }
        return token;
    },
};
