import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import ChatAnimationImage from "../../assets/images/icons/chat_circle.gif";
import './ErrorComponent.scss';
import { Auth } from  "../../libs/utilities/helpers/Auth";

const ErrorComponent = () => {
    const navigate = useNavigate();
    const [trueServiceCount, setTrueServiceCount] = useState(0);
    const user = Auth.getUser();

    useEffect(() => {
        // Ensure user object is valid
        if (user && user.accessibility && Array.isArray(user.accessibility)) {
            const count = user.accessibility.filter(service => service.status).length;
            setTrueServiceCount(count);
        } else {
            // Handle invalid user object or accessibility data
            console.error("Invalid user object or accessibility data:", user);
        }
    }, [user]);

    const handleTryAgain = () => {
        // Handle retry logic here if needed
        window.location.reload();
    };

    return (
        <div className="error-container1">
            <img className='animation1' src={ChatAnimationImage} alt="waiting-gif" width={100} height={100} />
            <h1>Lost in space ...</h1>
            {trueServiceCount > 1 && <h1 className='explore'>Explore other available services</h1>}
            <p>We're sorry, the service you're trying to access is currently out of reach.</p>
            <Button className="errorbutton1" onClick={handleTryAgain}>Try Again</Button>
        </div>
    );
};

export default ErrorComponent;
