import { Modal, Button } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { hideInfoDialog } from "../reducers/infoDialogSlice";

const InfoDialog = (props) => {
    const dialogData = useSelector((state) => state.infoDialog);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(hideInfoDialog())
    }

    const handleConfirm = () => {
        props.onConfirm()
    }

    return (
        <>
            <Modal
                title={dialogData.title}
                open={dialogData.show}
                onOk={handleConfirm}
                onCancel={handleClose}
                closeIcon={null} // Remove the close icon
                // footer={[
                //     <Button key="okay" type="primary5" onClick={() => {}}>
                //         okay
                //     </Button>,
                // ]}
            >
                <p>{dialogData.description}</p>
            </Modal>
        </>
    )
}

export default InfoDialog;