import { DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined, EyeOutlined } from "@ant-design/icons";
import Input from "antd/es/input/Input";
import { Button, message, Space, Table, Modal, Pagination, DatePicker, Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadReportPdf, getReportList, getInfoRetrieval, deleteReportPdf } from "../../apis/ReportGeneratorAPI";
import UploadMedia from "./components/UploadMedia";
import { formatDateToYYYYMMDD } from "../../libs/utilities/helpers/dateFormatter";
import "./ReportGenerator.scss";
import PageTour from "../../components/PageTour/PageTour";
import { toggleTour } from "../../reducers/tourSlice";
import ViewSvg from "../../assets/images/view.svg"
import Scrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import FileSaver from "file-saver";
import { calculatePageSize } from "../../libs/utilities/helpers/paginationHelper";
import { hideInfoDialog, showInfoDialog } from "../../reducers/infoDialogSlice";
import { deleteEarlyClaimsReport } from "../../apis/EarlyClaimsAPI";
import InfoDialog from "../../components/InfoDialog";

const { RangePicker } = DatePicker;

const ReportGenerator = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [allReport, setAllReport] = useState([]);
    const [filteredReport, setFilteredReport] = useState([]);
    const [filteredReportWithoutSort, setFilteredReportWithoutSort] = useState([]);
    const [modal2Open, setModal2Open] = useState(false);
    const expandNestedTable = useRef(null);

    const [retrivevalData, setRetrivevalData] = useState({
        fileName: "",
        data: []
    })
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 3,
        total: 0
    })
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: '',
    })

    const dateFormat = 'YYYY-MM-DD';
    const defaultDateRange = [
        dayjs().subtract(6, 'days'),
        dayjs()
    ];

    const columns = [
        {
            title: 'Name',
            dataIndex: 'document_name',
            key: 'document_name',
            sorter: (a, b) => { }
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Status',
            dataIndex: 'is_processed',
            key: 'is_processed',
            render: (_, record) => (
                <> {record.is_processed ? <div style={{ color: "" }}>Completed</div> : <div style={{ color: "" }}>In Progress</div>} </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="small">
                    {
                        record.is_processed ?
                            <>
                                <Tooltip
                                    placement="top" title="View Data"
                                >
                                    <Button style={{ width: "33px", display: "flex", justifyContent: "center", alignItems: "center" }} type="text"
                                        onClick={() => handelRetrivevalData(record)}
                                    >
                                        <EyeOutlined src={ViewSvg} />

                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    placement="top" title="Download document"
                                >
                                    <Button style={{ width: "33px", display: "flex", justifyContent: "center", alignItems: "center" }} type="text"
                                        onClick={() => handleDownloadPDF(record)}
                                    >
                                        <DownloadOutlined />
                                    </Button>
                                </Tooltip>

                            </>
                            :
                            <Tooltip title="Report not generated">
                                <Button type="text"><ExclamationCircleOutlined style={{ color: "gray" }} /></Button>
                            </Tooltip>
                    }
                    <Tooltip title="Delete document">
                        <Button onClick={() => startDocumentDelete(record.id)} type="text">
                            <DeleteOutlined/>
                        </Button>
                    </Tooltip>
                </Space>
            ),
        },
    ];
    useEffect(() => {
        if (expandNestedTable?.current) {
            let ps = new Scrollbar(expandNestedTable.current);
            return () => {
                if (ps) {
                    ps.destroy();
                    ps = null;
                }
            };
        }
    }, [retrivevalData]);

    useEffect(() => {
        if (!modal2Open && expandNestedTable.current) {
            const ps = new Scrollbar(expandNestedTable.current, {
                minScrollbarLength: 20
            });
            if (ps && ps.element) {
                ps.element.scrollTop = 0;
            }
            return () => {
                if (ps) {
                    ps.destroy();
                }
            };
        }
    }, [modal2Open]);

    useEffect(() => {
        const startDate = formatDateToYYYYMMDD(dayjs().subtract(6, 'days'));
        const endDate = formatDateToYYYYMMDD(dayjs());
        setDateRange({ startDate, endDate })
        getReport(startDate, endDate)

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const handleResize = () => {
        calculatePageSize(setPagination);
    }

    const [selectedDocumentIdToDelete, setSelectedDocumentIdToDelete] = useState();

    const startDocumentDelete = (doc_id) => {
        setSelectedDocumentIdToDelete(doc_id);
        dispatch(showInfoDialog({title: "Are you sure you want to delete?", description: ""}));
    }

    const confirmDocumentDelete = async () => {
        try {
            const response = await dispatch(deleteReportPdf({_id: selectedDocumentIdToDelete}))
            if(!response.error) {
                message.success("File deleted successfully");
                dispatch(hideInfoDialog());
                getReport(dateRange.startDate, dateRange.endDate)
            }
        } catch (e) {
            message.warning("Something went wrong!");
        }
    }


    const getReport = async (startDate, endDate) => {
        setFetchLoading(true);
        // const response = dispatch(getPCVCReport({ startDate, endDate }));
        const dateData = {
            startDate: startDate,
            endDate: endDate
        }
        const response = await dispatch(getReportList({ data: dateData }))
        const data = await response;
        if (!data.error) {
            setSearchText('');
            // {document_name, is_processed, createdAt, updatedAt}
            const sortedData = data.payload.data.report.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            const lists = sortedData.map((item, index) => (
                {
                    uid: index,
                    document_name: item.document_name,
                    is_processed: item.is_processed,
                    createdAt: formatDateToYYYYMMDD(item.createdAt), updatedAt: item.updatedAt,
                    id: item.id
                }));
            setPagination({ ...pagination, total: lists.length })
            setAllReport(lists);
            setFilteredReport(lists);
            setFilteredReportWithoutSort([...lists]);
            handleResize();
        }
        setFetchLoading(false);
    }

    const handleDateFilter = (dates, dateStrings) => {
        if (!dates) {
            return;
        }
        let startDate = formatDateToYYYYMMDD(dateStrings[0]);
        let endDate = formatDateToYYYYMMDD(dateStrings[1]);
        setDateRange({ startDate, endDate })
        getReport(startDate, endDate);
    };

    const [tableSorter, setTableSorter] = useState(null);

    const sortTableData = (pagination, filters, sorter) => {
        setTableSorter(sorter);
        if (sorter.columnKey === "document_name") {
            if (!sorter.order) {
                setFilteredReport(filteredReportWithoutSort);
                return;
            }
            const filtered = filteredReport.sort((a, b) =>
                sorter.order === "ascend" ? a.document_name.localeCompare(b.document_name) : b.document_name.localeCompare(a.document_name));
            setFilteredReport([...filtered]);
        }
    }

    const handlePageChange = (page, pageSize) => {
        setPagination({ ...pagination, current: page, pageSize })
    }

    const startIdx = (pagination.current - 1) * pagination.pageSize;
    const endIdx = startIdx + pagination.pageSize;
    const displayData = filteredReport.slice(startIdx, endIdx);
    const getEndOfDay = (date) => {
        const endOfDay = new Date(date);
        endOfDay.setHours(23, 59, 59, 999);
        return endOfDay;
    };

    const disabledDate = current => {
        return current && current > getEndOfDay(new Date());
    };

    const handleDownloadPDF = async (record) => {
        const docName = record.document_name.split('.');
        docName.pop();
        docName.join('');
        const downloadPdfdata = {
            type: "download",
            document_id: record.id
        }
        try {
            const response = await dispatch(getInfoRetrieval({ data: downloadPdfdata }));
            if (!response.error) {
                const unit8Array = new Uint8Array(response.payload.data.data)
                const blob = new Blob([unit8Array], { type: 'application/octet-stream' });
                FileSaver.saveAs(blob, `${docName}.docx`)
            }
        } catch (error) {
            // console.log(error);
        }
    };

    const handleSearch = (e) => {
        const searchString = e.target.value;
        setSearchText(searchString);
        const allData = [...allReport];
        const filtered = allData.filter(item => item.document_name.toLowerCase().includes(searchString.trim().toLowerCase()))
        setPagination({ ...pagination, current: 1, pageSize: 3, total: filtered.length })

        setFilteredReport(filtered);
        setFilteredReportWithoutSort([...filtered]);

        if (tableSorter?.columnKey === "document_name") {
            if (!tableSorter.order) {
                setFilteredReport(filteredReportWithoutSort);
                return;
            }
            const tempFiltered = filtered.sort((a, b) =>
                tableSorter.order === "ascend" ? a.document_name.localeCompare(b.document_name) : b.document_name.localeCompare(a.document_name));
            setFilteredReport([...tempFiltered]);
        }

    }

    const handleFileSelect = (file) => {
        setFileList(file)
    };

    const generateReport = async () => {
        if (fileList.length === 0) {
            message.warning("Please upload a file")
            return;
        }
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("type", "basic");
            formData.append("file", fileList[0]);
            const response = await dispatch(uploadReportPdf({ data: formData }));
            if (!response.error) {
                message.success("Report uploaded successfully.")
                setFileList([])
                getReport(dateRange.startDate, dateRange.endDate);
            }
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    const handelRetrivevalData = async (docid) => {
        const data = {
            type: "view",
            document_id: docid?.id
        }
        try {
            const response = await dispatch(getInfoRetrieval({ data }));
            const responseData = response?.payload?.data;
            setRetrivevalData({ fileName: responseData.document_name, data: responseData.document_label })
            setModal2Open(true)
        } catch (error) {
            // console.log(error);
        }
    }

    const tourState = useSelector((state) => state.tour);
    const handleTourClose = () => {
        dispatch(toggleTour({ show: false }))
        // setShowHandAnimation(false);
    }

    useEffect(() => {
    }, [retrivevalData])
    return (
        <>
            <div className="pcvc-container">
                <InfoDialog onConfirm={confirmDocumentDelete} />
                <PageTour show={tourState.show} handleShow={handleTourClose} target="ant-upload-drag" title="Start uploading document file to get report" />
                <div className="file-table">
                    <div className="table-actions">
                        <Input size="middle" value={searchText} onChange={(e) => handleSearch(e)} placeholder="Search" />
                        <RangePicker
                            className="file-datepicker"
                            onChange={handleDateFilter}
                            disabledDate={disabledDate}
                            defaultValue={defaultDateRange}
                            format={dateFormat}
                            allowClear={false}
                        />
                    </div>

                    <Table
                        columns={columns}
                        dataSource={displayData}
                        loading={fetchLoading}
                        style={{ overflowY: "auto" }}
                        pagination={false}
                        rowKey={(record) => record.id}
                        onChange={sortTableData}
                    />
                    <Pagination
                        current={pagination.current}
                        pageSize={pagination.pageSize}
                        total={+pagination.total}
                        onChange={handlePageChange} style={{ marginTop: "5px", float: "right" }} />

                </div>
                {/* ======= */}
                <div>
                    <UploadMedia
                        handleFileSelect={handleFileSelect}
                        fileList={fileList}
                    />
                    <div className="actions">
                        <Button type="primary" onClick={generateReport} disabled={loading}>
                            <div className='text'>Submit</div>
                        </Button>
                    </div>
                </div>

                <Modal
                    style={{ maxHeight: "90vh" }}
                    width={"50%"}
                    title={retrivevalData?.fileName}
                    centered
                    open={modal2Open}
                    onCancel={() => setModal2Open(false)}
                    cancelText="Close"
                    maskClosable={false}
                    className='view-modal'
                >
                    <div className="expanded-table-container" ref={expandNestedTable} >
                        {
                            retrivevalData?.data?.map((item, i) => (
                                <div key={i} className='propmt_ans_section'>
                                    <h4 className='view_propmt'>{item?.label}</h4>
                                    <p className='view_ans'>{item?.answer}</p>
                                </div>
                            ))
                        }

                    </div>
                </Modal>

            </div>
        </>
    )
}

export default ReportGenerator;