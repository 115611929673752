import React from 'react';
import { Upload, message } from 'antd';
import ArrowUpIcon from "../../../assets/images/icons/arrow_up.svg";
import { CloseRounded } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { toggleTour } from '../../../reducers/tourSlice';

const { Dragger } = Upload;

const UploadMedia = ({ handleFileSelect, fileList }) => {
    const dispatch = useDispatch();

    const beforeUpload = (file) => {
        if (file.type === "application/x-zip-compressed") {

            if ((file.size / 1024 / 1024) > 150) {
                message.error('File must be smaller than 150MB');
                return Upload.LIST_IGNORE;
            }

            // Checking and removing duplicate file
            const isDuplicate = fileList.some(existingFile => existingFile.name === file.name);
            if (isDuplicate) {
                return Upload.LIST_IGNORE;
            }

            return true;
        } else {
            message.error("Invalid File format. File should be in zip format");
            return Upload.LIST_IGNORE;
        }
    };

    const handleFileChange = (file) => {
        const data = file.fileList.map(item => item.originFileObj ? item.originFileObj : item)
        handleFileSelect(data);
    };

    const customRequest = ({ file, onSuccess }) => {
        if (file && file.name) {
            onSuccess("ok");
        }
    }

    const onRemove = () => {
        handleFileSelect([]);
    }

    const handleClickOnDragger = () => {
        dispatch(toggleTour({ show: false }));
    }

    return (
        <Dragger className='pcvc-upload'
            fileList={fileList}
            multiple={false}
            beforeUpload={beforeUpload}
            onChange={handleFileChange}
            customRequest={customRequest}
            showUploadList={false}
            maxCount={1}
            onClick={handleClickOnDragger}
            disabled={fileList.length > 0}
        >
            <div className='upload-container'>
                {
                    fileList.length === 0 ?
                    <div className='upload-action'>
                        <div className='title'>Drag and drop here or</div>
                        <button className="btn-outlined">
                            <div className='btn-outlined-content'>
                                <div className='text'>Upload documents </div>
                                <img width={29} src={ArrowUpIcon} />
                            </div>
                        </button>
                    </div>
                    :
                    <div className="upload-action" style={{ zIndex: 9999 }} onClick={() => { return; }}>
                        <div className="selectedList">
                            <div className="name">{fileList[0].name}</div>
                            <CloseRounded className="remove" onClick={onRemove} />
                        </div>
                    </div>
                }
            </div>
        </Dragger>

    );
};

export default UploadMedia;
