import React from 'react';
import './ErrorPage.scss'; 
import { useNavigate } from 'react-router-dom'; 
import Logo1 from '../../assets/images/galaxy.svg';
import Logo2 from '../../assets/images/hdfc_life_logo.svg'; 
import { Button } from 'antd';

const ErrorPage = () => {
  const navigate = useNavigate(); 

  const handleTryAgain = () => {
    localStorage.clear();
    navigate('/login');
  };

  return (
    <div className="error-wrapper">
      <div className="error-header">
        <img src={Logo2} alt="Logo 2" className="logo1" />
      </div>
      <div className="error-container">
        <img src={Logo1} alt="Logo 1" className="logo2" />
     
        <h1>
          Lost in space <br /> Please try again after sometime ...
        </h1>
        <p>We apologize for the inconvenience caused</p>
        
        <Button className="errorbutton" onClick={handleTryAgain}>
          Try again
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
