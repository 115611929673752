import React, { useEffect, useState } from 'react';
import { Upload, message, Switch } from 'antd';
import ArrowUpIcon from "../../assets/images/icons/arrow_up.svg";
import { CloseRounded } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { toggleTour } from '../../reducers/tourSlice';
import './UploadMedia.scss';

const { Dragger } = Upload;

const UploadMedia = ({ handleFileSelect, fileList, fileClear }) => {
    const dispatch = useDispatch();
    const [fileType, setFileType] = useState('.xlsx'); // Default file type
    const [selectedFileName, setSelectedFileName] = useState(null);

    useEffect(() => {
        setSelectedFileName(null)
    }, [fileClear])

    const beforeUpload = (file) => {
        const allowedFileTypes = fileType === '.xlsx' ? ['.xlsx'] : ['.zip'];
        const isAllowedType = allowedFileTypes.includes(file.name.substring(file.name.lastIndexOf('.')));

        if (!isAllowedType) {
            message.error(`Invalid File format. File should be in ${fileType} format`);
            return Upload.LIST_IGNORE;
        }

        if ((file.size / 1024 / 1024) > 150) {
            message.error('File must be smaller than 150MB!');
            return Upload.LIST_IGNORE;
        }

        // Checking and removing duplicate file
        const isDuplicate = fileList.some(existingFile => existingFile.name === file.name);
        if (isDuplicate) {
            return Upload.LIST_IGNORE;
        }

        return true;
    };

    const handleFileChange = (file) => {
        // file.fileList.map(item => item.originFileObj ? item.originFileObj : item)
        const selectedFile = file.fileList.map(item => item.originFileObj ? item.originFileObj : item)
        handleFileSelect(selectedFile, fileType); // Pass fileType to parent component
        if (selectedFile) {
            const maxLength = 20; // Set your desired maximum length
            const truncatedFileName = selectedFile[0].name.length > maxLength 
                ? selectedFile[0].name.substring(0, maxLength) + '...' 
                : selectedFile[0].name;
            setSelectedFileName(truncatedFileName); 
        } else {
            setSelectedFileName(null); // Reset selected file name if no file is selected
        }
    };

    const customRequest = ({ file, onSuccess }) => {
        if (file && file.name) {
            onSuccess("ok");
        }
    }

    const onRemove = () => {
        handleFileSelect([], fileType); // Pass fileType to parent component
        setSelectedFileName(null); // Reset selected file name
    }

    const handleClickOnDragger = () => {
        dispatch(toggleTour({ show: false }));
    }

    const handleFileTypeChange = (checked) => {
        const newFileType = checked ? '.zip' : '.xlsx';
        setFileType(newFileType);
        handleFileSelect([], newFileType); // Reset fileList and pass new fileType to parent component
    }

    return (
        <div className='wrapper'>
            <div className="upload-container">
                {/* Left part for file type toggle */}
                <div className="file-type-toggle">
                    <div className='title1'>File type</div>
                    <Switch
                        checkedChildren="zip"
                        unCheckedChildren="xlsx"
                        defaultChecked={false}
                        onChange={handleFileTypeChange}
                    />
                    <span></span>
                </div>

                {/* Vertical line */}
                <div className="vertical-line"></div>

                {/* Right part for upload button */}
                <Dragger
                    className='pcvc-upload'
                    fileList={fileList}
                    multiple={false}
                    beforeUpload={beforeUpload}
                    onChange={handleFileChange}
                    customRequest={customRequest}
                    showUploadList={false}
                    maxCount={1}
                    onClick={handleClickOnDragger}
                    disabled={fileList.length > 0}
                >
                    {selectedFileName ? (
                        <div className="selected-file">
                            <div className="selected-file-name">{selectedFileName}</div>
                            <CloseRounded className="remove" onClick={onRemove} />
                        </div>
                    ) : (
                        <div className='upload-action'>
                            <div className='title'>Drag and drop here or</div>
                            <button className="btn-outlined">
                                <div className='btn-outlined-content'>
                                    <div className='text'>Upload documents </div>
                                    <img width={29} src={ArrowUpIcon} alt="Upload Icon" />
                                </div>
                            </button>
                        </div>
                    )}
                </Dragger>
            </div>
        </div>
    );
};

export default UploadMedia;
