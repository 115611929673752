const CryptoJS = require('crypto-js');

export const encryptData = async (data) => {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY);
    var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV);
    try {
        return {
            data: CryptoJS.AES.encrypt(data, key, { iv: iv }).toString()
        }
        // return JSON.parse(data);

    } catch (error) {
        // console.log(error);
    }
}

export const decryptData = async (data) => {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY);
    var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV);
    try {
        var decryptedWA = CryptoJS.AES.decrypt(data, key, { iv: iv });
        return decryptedWA.toString(CryptoJS.enc.Utf8)

    } catch (error) {
        // console.log(error);
    }
}
