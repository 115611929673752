import { Col, Input, Form, Row, Button, Carousel } from "antd";
import React, { useEffect } from "react";
import "../assets/styles/login.scss";
import LoginAIImage from '../assets/images/icons/Login_AI_animation.gif'
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../apis/AuthAPI";
import { useNavigate } from "react-router-dom";
import { Auth } from  "../libs/utilities/helpers/Auth";


const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authLoading = useSelector((state) => state.auth.authLoading);

    const onLogin = async (values) => {
        const response = await dispatch(login({ attar: values }));
        const user = Auth.getUser();
        if (response.meta.requestStatus === "fulfilled") {
            const accessibility = user?.accessibility.filter(item => item.status).sort((a, b) => a.index - b.index);
            navigate("/" + accessibility[0].service);
        }
    };

    useEffect(() => {
        if (Auth.isLoggedIn()) {
            navigate("/");
        } else {
            Auth.deregister();
        }
    }, [navigate]);

    return (
        <>
            <div>
                <Row span={24} className="login-container" >
                    <Col xs={16} sm={16} md={14} lg={14} xl={14} className="bg-left">
                        <Row align="middle" className="login-wrapper" gutter={2}>
                            <Col span={20} offset={4}>
                                <Row>
                                    <Col xs={16} sm={8} md={8} lg={8} xl={8} className="logo-container">

                                        <span className="portal-logo">Galaxy</span>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="hello-message">
                                        Hello! How can I assist you today.
                                </Col>
                                </Row>
                                <Row>
                                    <Col className="login-heading">Log in</Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Form
                                            name="normal_login"
                                            layout="vertical"
                                            onFinish={onLogin}
                                        >
                                            <Form.Item
                                                name="username"

                                                rules={[
                                                    {

                                                        required: true,
                                                        message: "Please input your Employee Code!",
                                                    },
                                                ]}
                                                className=""
                                            >
                                                <Input

                                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                                    variant="outlined"
                                                    placeholder="Employee Code"
                                                    style={{ height: "48px" }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="secretkey"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please input your Password!",
                                                    },
                                                ]}

                                            >
                                                <Input
                                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                                    variant="outlined"
                                                    type="password"
                                                    placeholder="Password"
                                                    style={{ height: "48px" }}
                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <div style={{ paddingTop: "2rem", paddingBottom: "1rem" }}>
                                                    <Button
                                                        className="login-button"
                                                        size="large"
                                                        type="primary"
                                                        htmlType="submit"
                                                        loading={authLoading}
                                                    >
                                                        <span>Log in</span>
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {/* </div> */}
                    </Col>
                    <Col xs={8} sm={8} md={10} lg={10} xl={10}>
                        <div className="bg-right-container">
                            <div>
                                <img className='AnimationImg' alt="Galaxy AI" src={LoginAIImage} />
                            </div>
                            <Carousel dotPosition="bottom" effect="fade" autoplay>
                                <div>
                                    <h3 className="carousel-page">Summarizer</h3>
                                    <p className="carousel-text"> Transforms call recordings, chat conversations, emails, and past interactions into concise summaries, offering a quick snapshot of customer needs and preferences.</p>
                                </div>
                                <div>
                                    <h3 className="carousel-page">Report Analyzer</h3>
                                    <p className="carousel-text"> Use it to Convert financial data into practical insights and concise report summaries. Our AI transforms complex reports into actionable summaries, highlighting key performance indicators, strengths, weaknesses, and more.</p>
                                </div>
                                <div>
                                    <h3 className="carousel-page">Enterprise GPT</h3>
                                    <p className="carousel-text"> Web application that allows you to chat over your  document using GenAI. This tool provides a seamless interface for interacting with your document, exploring search results, and engaging in a conversation.</p>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Login;
