import "../../assets/styles/analyser.scss";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Col, Input, Row } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { resetAnalyserData, setAnalyserPrompt } from '../../reducers/analyserSlice';
import { useDispatch, useSelector } from "react-redux";
import DownloadSvg from "../../assets/images/download.svg"
import BotIcon from "../../assets/images/1100838311-preview 1.svg";
import ChatAnimationImage from "../../assets/images/icons/chat_circle.gif"
import SessionTimeoutModal from "../../components/SessionTimeoutModal";
import { Spin } from "antd";
import UserIcon from "../../assets/images/27470347_7309691 1.svg";
import { message } from 'antd';
import { reportAnalyser } from "../../apis/ReportAnalyserAPI";
import useMessage from "../../hooks/useMessage";
import { v4 as uuid4 } from 'uuid';
import ReportUploadDragger from "./components/ReportUploadDragger";
import PageTour from "../../components/PageTour/PageTour";
import { toggleTour } from "../../reducers/tourSlice";

const ReportAnalyser = () => {
    const dispatch = useDispatch();
    const { errorMessage, contextHolder } = useMessage();
    const [sessionTimeoutVisible, setSessionTimeoutVisible] = useState(false);
    const [reportdata, setReportdata] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [analyserRequestObject, setAnalyserRequestObject] = useState({
        prompt: "",
        nword: "150",
        max_new_tokens: "1024",
        files: [],
    });
    const [isWaiting, setIsWaiting] = useState(false);
    const { analyserData, analyserPrompt } = useSelector((state) => state.analyser);
    const chatContainerRef = useRef(null);
    const scrollToBottom = () => { if (chatContainerRef.current) { chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight; } };

    useEffect(() => {
        scrollToBottom();
    }, [chatHistory, analyserRequestObject.prompt]);

    useEffect(() => {
        dispatch(resetAnalyserData());
    }, []);

    useEffect(() => {
        const allItemsLoaded = chatHistory.every(item =>
            item.response.every(response => !response.isLoading)
        );
        if (allItemsLoaded) {
            setAnalyserRequestObject(prev => ({
                ...prev,
                prompt: "",
                files: []
            }));
        }
    }, [chatHistory]);

    const handleSessionTimeoutOk = () => {
        setSessionTimeoutVisible(false);
    };

    const handlePromptChange = useCallback((e) => {
        setAnalyserRequestObject((pre) => {
            return { ...pre, prompt: e.target.value };
        });
    }, []);

    const verifyForm = () => {
        if (!analyserRequestObject.files || analyserRequestObject.files.length === 0) {
            errorMessage("Please select a file");
            return false;
        }

        if (!analyserRequestObject.prompt) {
            errorMessage("Please enter a prompt");
            return false;
        }

        if (!analyserRequestObject.nword) {
            errorMessage("Please enter a length");
            return false;
        }
        return true;
    };

    const generateAnalyser = async () => {
        setReportdata(true)
        if (!verifyForm()) {
            return null;
        }
        const historyId = uuid4();
        let data = {
            historyId: historyId,
            query: analyserRequestObject.prompt,
            response: []
        }
        setChatHistory((prev) => [...prev, data]);
        for (var i in analyserRequestObject?.files) {
            const uuid = uuid4();
            data.response.push({ data: null, responseType: "success", uuid: uuid, isLoading: true });
        }

        for (var i in data.response) {
            const formData = new FormData();
            formData.append("prompt", analyserRequestObject.prompt);
            formData.append("nword", analyserRequestObject.nword);
            formData.append("max_new_tokens", analyserRequestObject.max_new_tokens);
            formData.append("files", analyserRequestObject?.files[i].originFileObj);
            try {
                setIsWaiting(false)
                if (formData) {
                    setIsWaiting(true)
                    const response = await dispatch(reportAnalyser({ data: formData, queryId: data.response[i].uuid }));
                    if (response.meta.requestStatus === "fulfilled") {

                        const queryId = response.meta.arg.queryId;
                        const targetIndex = data.response.findIndex(item => item.uuid === queryId);
                        data.response[targetIndex].data = {
                            // prompt_answer: response.payload.data.result[0].prompt_answer,
                            // source: response.payload.data.result[0].source,
                            // summary: response.payload.data.result[0].summary,
                            tdata: response.payload.data.result[0]
                        };
                        data.response[targetIndex].isLoading = false;

                        setIsWaiting(false);
                        if (parseInt(i) + 1 == analyserRequestObject.files.length) {
                            await dispatch(setAnalyserPrompt(analyserRequestObject.prompt));
                            // navigate("download");
                        }

                    } else {
                        const queryId = response.meta.arg.queryId;
                        const targetIndex = data.response.findIndex(item => item.uuid === queryId);

                        data.response[targetIndex].data = "Something went wrong!";
                        data.response[targetIndex].responseType = "error";
                        data.response[targetIndex].isLoading = false;
                    }
                    if (response.meta.requestStatus === "rejected") {
                        setIsWaiting(false);
                        errorMessage("Please try again. Something went wrong");
                    }
                }
            } catch (error) {
                errorMessage(error.message);
                setIsWaiting(false);
            }
        }

        setChatHistory((prev) => {
            const prevHistory = [...prev];
            const targetIndex = prevHistory.findIndex(item => item.uuid === historyId);
            prevHistory[targetIndex] = data;
            return prevHistory;
        });
    };

    const downloadFile = (value, name, type = "text/plain") => {
        const element = document.createElement("a");
        const file = new Blob([value], { type: type });
        element.href = URL.createObjectURL(file);
        element.download = name;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    const handleDownload = (data) => {
        if (data && data.length > 0) {
            const csvArray = [];
            let headers = [
                "source",
                ...Object.keys(data[0].data.tdata).filter(
                    (header) => header !== "summary" && header !== "source"
                ),
            ];
            csvArray.push(headers.join(","));
            data.forEach((data, index) => {
                const { prompt_answer, source } = data?.data?.tdata;
                const summary = data?.data.tdata?.summary === null || undefined ? "" : data.data.tdata?.summary
                let summaryText = "{\\rtf1\\ansi \\b";
                summaryText += `\\cf1 \\fs34 \\b ${source}: \\par \\cf0 \\par\\fs26${summary}\\par\\par\\par`;
                summaryText += `\\cf1 \\fs30 \\b ${analyserPrompt}: \\par \\cf0 \\par\\fs26${prompt_answer}\\par\\par\\par`;
                const values = headers?.map((header) => {
                    const value = data.data.tdata[header];
                    return `"${value || ""}"`;
                });
                summaryText += "\\b0 ";
                summaryText += "}";
                downloadFile(summaryText, `${source} summary.rtf`, "application/rtf");
                csvArray.push(values.join(","));
            });
            const csvContent = csvArray.join("\n");
            downloadFile(csvContent, "dataTable.csv", "application/csv");

        }
    };

    const handelsingle = (e) => {
        const prompt_answer = e.data?.tdata?.prompt_answer;
        const source = e.data.tdata?.source;
        const summary = e.data.tdata?.summary === null || undefined ? "" : e.data.tdata?.summary;
        let summaryText = "{\\rtf1\\ansi \\b";
        summaryText += `\\cf1 \\fs34 \\b ${source}: \\par \\cf0 \\par\\fs26${summary}\\par\\par\\par`;
        summaryText += `\\cf1 \\fs30 \\b ${analyserPrompt}:\\par \\cf0 \\par\\fs26${prompt_answer}\\par\\par\\par`;
        summaryText += "\\b0 ";
        summaryText += "}";
        downloadFile(summaryText, `${source} summary.rtf`, "application/rtf");
    }

    const handleFileSelect = (file) => {
        setAnalyserRequestObject((pre) => {
            return { ...pre, files: file };
        });
    };
    const handelWordChange = (word) => {
        setAnalyserRequestObject((pre) => {
            return { ...pre, nword: word };
        });
    };

    const tourState = useSelector((state) => state.tour);
    const handleTourClose = () => {
        dispatch(toggleTour({ show: false }))
    }
    return (
        <div className="analyser-container">
            <PageTour show={tourState.show} handleShow={handleTourClose} target="tour-show-report-analyser" title="Start adding your document to get analysed report" />
            {contextHolder}

            <div className="report-chat-content" ref={chatContainerRef}>

                {reportdata === false ? (
                    <Row className="main-div-analyzer">
                        <Col xs={24} >
                            <div className="img-div">
                                <img
                                    className="animation"
                                    src={ChatAnimationImage}
                                    alt="waiting-gif"
                                    width={100}
                                    height={100}
                                />
                            </div>
                            <div className="heading-div">
                                <h2 className="analyzerHeading">
                                    Extract financial Data
                                </h2>
                                <h2 className="analyzerHeading">
                                    with the help of AI
                                </h2>
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <div className="chatbot-content-report" >
                        {chatHistory.map((history) => (
                            <>
                                <div className={`chat-message user`}>
                                    <span className='user-message'>{history.query}</span>
                                    <img className='user-icon' src={UserIcon} alt="user-icon" width={40} height={40} />
                                </div>
                                {
                                    [...history.response].filter(item => item.data).map((chat) => (
                                        <>
                                            {chat.responseType === "error" ? (
                                                <div className={`chat-message bot`}>
                                                    <img className='bot-icon' src={BotIcon} alt="bot-icon" width={40} height={40} />
                                                    <span className={`bot-message ${chat.responseType}`}>
                                                        Something went wrong!
                                                    </span>
                                                </div>
                                            ) : (
                                                <div className={`chat-message bot`}>
                                                    <img className='bot-icon' src={BotIcon} alt="bot-icon" width={40} height={40} />
                                                    <span className={`bot-message ${chat.responseType}`}>
                                                        <span className="pdfname">{chat.data.tdata.source}</span>
                                                        <br />
                                                        <br />
                                                        {chat.data.tdata.prompt_answer}
                                                        <br />
                                                        <br />
                                                        <div className="downloaddiv">
                                                            <span className="downloadtext" onClick={() => { handelsingle(chat) }}>Download Report</span>
                                                            <img width={18} height={20} src={DownloadSvg} />
                                                        </div>
                                                    </span>
                                                </div>
                                            )}
                                        </>
                                    ))
                                }
                                {
                                    history.response.some(item => item.isLoading) && (
                                        <div className={`chat-message bot`}>
                                            <img className='bot-icon' src={BotIcon} alt="bot-icon" width={40} height={40} />
                                            <Spin size="small" style={{ marginLeft: 8 }} />
                                        </div>
                                    )
                                }
                                {!history.response.some(item => item.isLoading || item.data === "Something went wrong!" || item.data == null) && (
                                    <div className={`chat-message bot`}>
                                        <img className='bot-icon' src={BotIcon} alt="bot-icon" width={40} height={40} />
                                        <span className={`bot-message ${message.responseType} table-botMessage`}>
                                            <div className="tablediv">
                                                <table className="tableContent">
                                                    <thead className="tablecontenthead">
                                                        <tr>
                                                            <th className="tablecontentth">PDF File</th>
                                                            {Object.keys(history.response[0].data?.tdata || {}).map(key => (
                                                                key !== "prompt_answer" && key !== "source" && key !== "summary" && (
                                                                    <th key={key} className="tablecontentth">{key}</th>
                                                                )
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {history.response.map((responseItem, index) => (

                                                            <tr key={index}>
                                                                <td className="tablecontentth">{responseItem.data?.tdata?.source || ''}</td>
                                                                {Object.keys(history.response[0].data?.tdata || {}).map((key, cellIndex) => (
                                                                    key !== "prompt_answer" && key !== "source" && key !== "summary" && (
                                                                        <td key={cellIndex} className="tablecontentth">
                                                                            {responseItem.data?.tdata?.[key] || ''}
                                                                        </td>
                                                                    )
                                                                ))}
                                                            </tr>

                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="downloaddiv" style={{ marginTop: "10px" }} onClick={() => { handleDownload(history.response) }}>
                                                <span className="downloadtext"  >Download All </span>
                                                <img width={18} height={20} src={DownloadSvg} />
                                            </div>
                                        </span>
                                    </div>
                                )}
                            </>
                        ))}
                    </div>
                )
                }

            </div>
            <div className="tour-show-report-analyser">
                <div>
                    <ReportUploadDragger
                        handleFileSelect={handleFileSelect}
                        fileList={analyserRequestObject.files}
                        handelWordChange={handelWordChange}
                        wordValue={analyserRequestObject.nword}
                    />

                </div>
                <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>

                    <Input
                        disabled={isWaiting}
                        value={analyserRequestObject.prompt}
                        className="input-report"
                        placeholder="Write a prompt to get report"
                        onChange={handlePromptChange}
                        autoSize={{ minRows: 6, maxRows: 6 }}
                    />

                    <button
                        disabled={isWaiting}
                        onClick={generateAnalyser}
                        className="btn2"
                    >
                        Analyse
                    </button>
                </div>
            </div>
            <SessionTimeoutModal visible={sessionTimeoutVisible} handleOk={handleSessionTimeoutOk} />

        </div >
    );
};

export default ReportAnalyser;
