import { Provider } from "react-redux";
import store from "./store/configureStore"
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { ConfigProvider, theme } from "antd";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
    const { defaultAlgorithm } = theme;
    // const [isDarkMode, setIsDarkMode] = useState(false);
    return (
        <>
            <ConfigProvider
                theme={{
                    // algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
                    algorithm: defaultAlgorithm,
                    components: {},
                    token: {
                        fontFamily: "'Inter', sans-serif",
                    }
                }}
            >
                <ErrorBoundary handleLogout={() => {console.log('logout')}}>
                    <Provider store={store}>
                        <RouterProvider router={router} />
                    </Provider>
                </ErrorBoundary>
            </ConfigProvider>

        </>
    );
}

export default App;
