export class User {
    constructor(apiObj) {
        this.Id = apiObj.id;
        this.UserId =apiObj.user_id;
        this.Txnid=apiObj.txn_id
        this.Username = apiObj.username;
        this.Role = apiObj.role;
        this.Token = apiObj.token;
        this.Department = apiObj.department;
        this.accessibility = apiObj.accessibility;
    }
}
