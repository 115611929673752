import { useEffect, useState } from "react";
import LongArrowImage from '../../assets/images/icons/big_arrow.svg';
import BotIcon from "../../assets/images/1100838311-preview 1.svg";
import FileUploadImage from "../../assets/images/DocumentGPT_file_upload.png";
import HandAnimatedIcon from "../../assets/images/icons/animated_hand.gif"
import  pb_tour from "../../assets/images/pb_tour.png"
import "./TourDocGPT.scss"

const TourDocGPT = ({ show, handleShow, page, tourStep, changeStep }) => {

  const [showIntro, setShowIntro] = useState(false);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    let timeoutRef;

    if (!show) {
      setIsShow(show);
      timeoutRef = setTimeout(() => {
        setShowIntro(false);
        clearTimeout(timeoutRef)
      }, 500)
    } else {
      setShowIntro(true);
      setTimeout(() => {
        setIsShow(show);
        clearTimeout(timeoutRef)
      }, [500])
    }
    return () => {
      clearTimeout(timeoutRef)
    }
  }, [show])

  const handleChangeStep = (e, step) => {
    if (e.type === "click") {
      changeStep(step)
    }
  }

  return (
    <>
      { showIntro &&
        <div className={`intro-overlay-docGPT ${isShow ? 'show' : ''}`} style={{ alignItems: tourStep === 0 ? "center" : "flex-end" }}>

          {/* First Step */}

          {tourStep === 0 && <div className="intro-overlay-content-first">
            <div className="left">
              <div className="prompt-text">
                {page == "process-guru" ? null : 'Create Folder And Upload Documents To Begin Your Journey'}
              </div>
              <button className="btn-got-it" onClick={(e) => handleChangeStep(e, 1)}>I GOT IT</button>
              <div className="arrow-image">
                <img src={LongArrowImage} alt="" />
              </div>
            </div>
            <div className="middle">
              {page == "process-guru" ?
                <img src={pb_tour} width={450} alt="" />
                :
                <img src={FileUploadImage} width={500} alt="" />
              }
              <div className="hand-animation">
                <img src={HandAnimatedIcon} width={80} alt="" />
              </div>
            </div>
            <div className="right">

            </div>
          </div>}

          {/* Second Step */}

          {tourStep === 1 && <div className="intro-overlay-content">
            <div className="left">
              <div className="content">
                <div className="prompt-text">
                  Start Typing Your Prompt To See The Magic
              </div>
                <button className="btn-got-it" onClick={(e) => handleChangeStep(e, 2)}>I GOT IT</button>
                <div className="arrow-image">
                  <img src={LongArrowImage} alt="" />
                </div>
              </div>
            </div>
            <div className="middle" style={{ paddingBottom: "20px" }}>
              <div className="intro-card">
                <div className="top">
                  <div className="icon">
                    <img src={BotIcon} width={70} alt="" />
                  </div>
                  <div className="name">
                    "Hello, I AM GALAXY"
                </div>
                </div>
                <div className="bottom">
                  <div className="prompt">"PROMPT EXAMPLE"</div>
                  <div className="example">
                    {page == "process-guru" ?
                      'Give me a summary of the change in mode process?'
                      : 'What is the age limit?'
                    }
                  </div>
                  <div className="description">
                    <div className="description-content">
                      {page == "process-guru" ? (
                        <>
                          <span>Change in Mode Process Summary</span>
                          <br /><br />
                     The Change in Mode process involves the following steps:<br /><br />
                     1)<span>Customer Request</span>: Customer submits a request for mode change through email or walk-in to the branch.<br />
                     2)<span>Scrutiny</span>: Central Operations team verifies the request and documents submitted by the customer.&nbsp;&nbsp;<span>...</span>
                        </>
                      )
                        :
                        (
                          <>
                            The age limit for HDFC Life Click 2 Protect Elite is
                        <span> 18-45 years for entry and 33-75 years for maturity.</span>
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="last">
            </div>
          </div>}

        </div>}
    </>)
}

export default TourDocGPT;