import { createAsyncThunk } from "@reduxjs/toolkit";
import setBaseURL from "../libs/utilities/helpers/BaseURLHelper";
import { api } from "./config/axiosConfig";


const DEV_BASE_URL = process.env.REACT_APP_BASE_URL_OTHER_SERVICES;

export const getCallReport = createAsyncThunk(
    "/summary/calls/report",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/summary/calls/report", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const downloadCallReport = createAsyncThunk(
    "/summary/calls/report/download",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/summary/calls/report/download", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteCallReport = createAsyncThunk(
    "/summary/calls/report/delete",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/summary/calls/report/delete", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);



export const uploadCallReport = createAsyncThunk(
    "/summary/calls",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/summary/calls", data, {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);