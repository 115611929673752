import { message } from "antd";
import { useCallback } from "react";

const useMessage = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const errorMessage = useCallback(
        (content, options = {}) => {
            messageApi.open({
                type: "error",
                content: content,
                ...options,
            });
        },
        [messageApi]
    );

    return {
        contextHolder,
        errorMessage,
    };
};

export default useMessage;
