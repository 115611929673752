import React, { useState, useEffect, useRef } from "react";
import { Button } from "antd";
import "../assets/styles/bot2.scss";
import ChatAnimationImage from "../assets/images/icons/chat_circle.gif"
import UserIcon from "../assets/images/27470347_7309691 1.svg";
import BotIcon from "../assets/images/1100838311-preview 1.svg";
import HandAnimatedIcon from "../assets/images/icons/animated_hand.gif"
import { getOpenChatResponse } from "../apis/ChatAIAPI";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { toggleTour } from "../reducers/tourSlice";
import TourChatAI from "./TourChatAI/TourChatAI";
import ReactQuill from "react-quill";
import { v4 as uuidv4 } from 'uuid';
import { toggleResponseReceived } from "../reducers/openChatSlice";
// Define the Chatbot2 component
const OpenChatBot = ({ showPrompt, historyChat, prompt }) => {
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState("");
  const [currentInstance, setCurrentInstance] = useState([]);
  const [isWaiting, setIsWaiting] = useState(false);
  const chatDivRef = useRef(null);
  const quillRef = useRef(null);
  const openChat = useSelector((state) => state.openChat);
  const tourState = useSelector((state) => state.tour);
  const [showHandAnimation, setShowHandAnimation] = useState(false);

  //set bot loader false if getting chatHistory from redux
  useEffect(() => {
    if (openChat.currentChatHistory) {
      setIsWaiting(false);
    }
  }, [openChat])

  //scroll auto if get response from api
  useEffect(() => {
    chatDivRef.current.scrollTop = chatDivRef.current.scrollHeight;
  }, [currentInstance]);

  //timeout for tour show and hide
  useEffect(() => {
    let timeout;
    if (tourState.show) {
      timeout = setTimeout(() => {
        setShowHandAnimation(true);
      }, 1000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [tourState])

  useEffect(() => {
    setUserInput(prompt);
  }, [prompt])

  //when click on chatHistory in sidebar then append that history in currentInstance to show in UI.
  useEffect(() => {
    setCurrentInstance(historyChat);
  }, [historyChat]);

  //function for the handel api response
  const handleChatSubmit = async () => {
    const strippedText = new DOMParser().parseFromString(userInput, 'text/html').body.textContent; //parse the userInput
    setIsWaiting(true); // add isWaiting for the loader  
    const uuid = uuidv4(); //generate uuid for every propmt 

    //create object of user propmt
    const promptObj = {
      additional_kwargs: {},
      example: false,
      type: "human",
      content: strippedText,
      uuid: uuid,
    };

    setCurrentInstance(prev => [...prev, promptObj]);
    setUserInput('');
    try {
      const payload = {
        prompt: strippedText,
        conversation_history_array: [...currentInstance],
      };
      const response = await dispatch(getOpenChatResponse(payload));
      if (response.error?.message === "Rejected" || response.error) {
        //handel if any error in api or fail the api

        setCurrentInstance(prev => {
          const updatedChatHistory = [...prev];
          //add the response object at position based on indexing with match uuid
          const promptIndex = updatedChatHistory.findIndex(message => message.uuid === uuid);
          if (promptIndex !== -1) {
            //splice the array based on Uuid add the like propmtindex is the userpropmt add 1 in that after that add bot response and 0 means remove none of the element.
            updatedChatHistory.splice(promptIndex + 1, 0, {
              example: false,
              type: "ai",
              content: "Something went wrong. Please try later!",
              responseType: "error"
            });
          } else {
            // setCurrentInstance([]);
          }
          return updatedChatHistory;
        });
        dispatch(toggleResponseReceived())

      } else {
        setCurrentInstance(prev => {
          const updatedChatHistory = [...prev];

          //find the propmt base on uuid
          const promptIndex = updatedChatHistory.findIndex(message => message.uuid === uuid);
          //add the response object at position based on indexing with match uuid
          if (promptIndex !== -1) {
            //splice the array based on Uuid add the like propmtindex is the userpropmt add 1 in that after that add bot response and 0 means remove none of the element.
            updatedChatHistory.splice(promptIndex + 1, 0, {
              additional_kwargs: {},
              example: false,
              type: "ai",
              content: response.payload.data.response,
            });
          } else {
            // updatedChatHistory.push({ content: 'Prompt not found!', type: 'ai', responseType: "error", example: false });
            // setCurrentInstance([]);
          }
          return updatedChatHistory;
        });
        dispatch(toggleResponseReceived())

      }
      setIsWaiting(false);
    } catch (error) {
      // dispatch(toggleResponseReceived())
      //catch the any error and show the resonse in ui something went wrong...
      setCurrentInstance(prev => {
        const updatedChatHistory = [...prev];
        //add the response object at position based on indexing with match uuid
        const promptIndex = updatedChatHistory.findIndex(message => message.uuid === uuid);
        if (promptIndex !== -1) {
          //splice the array based on Uuid add the like propmtindex is the userpropmt add 1 in that after that add bot response and 0 means remove none of the element.
          updatedChatHistory.splice(promptIndex + 1, 0, {
            content: 'Something went wrong!', type: 'ai', responseType: "error", example: false
          });
        } else {
          // setCurrentInstance([]);
        }
        return updatedChatHistory;
      });
      dispatch(toggleResponseReceived())

      setIsWaiting(false);
    }
    setUserInput("");
  };

  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        // If Shift + Enter is pressed, insert a newline
        insertNewLine();
      } else {
        // If only Enter is pressed, call your function
        // yourineFunction();
        setUserInput('')
        handleChatSubmit();
      }
      // Prevent default behavior to avoid Quill's default newline behavior
      event.preventDefault();
    }
  };

  const insertNewLine = () => {
    const quill = quillRef.current.getEditor();
    const selection = quill.getSelection();
    if (selection) {
      // quill.insertText(selection.index, '\n');
      // quill.setSelection(selection.index + 1);
    }
  };

  const handleTourClose = () => {
    dispatch(toggleTour({ show: false }))
    setShowHandAnimation(false);
  }

  return (
    <div className="chatbot-card" size="large" style={{ display: showPrompt ? "none" : "contents" }}>
      <TourChatAI show={tourState.show} handleShow={handleTourClose} page="chat-ai" />
      <div className="open-chat-content" ref={chatDivRef}>
        {/* if currentchatInstance is empty then show the propmt div */}
        {currentInstance?.length == 0 ? (
          <div className="new-instance-section">
            <div className="prompt-img">
              <img
                className="animation"
                src={ChatAnimationImage}
                alt="waiting-gif"
                width={100}
                height={100}
              />
            </div>
            <p className="text5">"I am waiting for a prompt"</p>
          </div>
        ) : (
          (isWaiting ? [...currentInstance, "loader"] : currentInstance)?.map(
            //showing spinner if message is loader
            (message, index) =>
              message == "loader" ? (
                <div className={`chat-message bot`}>
                  <img
                    className="bot-icon"
                    src={BotIcon}
                    alt="bot-icon"
                    width={40}
                    height={40}
                  />
                  <span className="bot-message">
                    <Spin />
                  </span>
                </div>
              ) : (
                // render the data in ui if bot response the float left and if human rsponse then float right
                <div key={index} className={`chat-message ${message.type}`}>
                  {message.type === "human" ? (
                    <>
                      <span className="user-message">{message.content}</span>
                      <img
                        className="user-icon"
                        src={UserIcon}
                        alt="user-icon"
                        width={40}
                        height={40}
                      />
                    </>
                  ) : (
                    <>
                      <img
                        className="bot-icon"
                        src={BotIcon}
                        alt="bot-icon"
                        width={40}
                        height={40}
                      />
                      <span className={`bot-message ${message.responseType}`}>{message.content}</span>
                    </>
                  )}
                </div>
              )
          )
        )}
      </div>
      <div className="input-container" >
        <div className="chat-input" style={{ alignItems: "flex-end", gap: "20px" }}>
          <div className="quill-prompt-container">
            <ReactQuill
              placeholder="Type your prompt..."
              ref={quillRef}
              className="quill-prompt"
              theme="bubble"
              modules={{ toolbar: false }}
              value={userInput}
              onChange={(e) => setUserInput(e)}
              onKeyDown={handleKeyPress}
            />
          </div>
          <Button
            className="chat-input-button"
            type="primary"
            onClick={handleChatSubmit}
            disabled={isWaiting || userInput.length == 0 || userInput === "<p><br></p>"}
          >
            Submit
          </Button>
          {(tourState.show && showHandAnimation) && <div className="hand-animated" style={{ position: "absolute", zIndex: "10000", bottom: "0px" }}>
            <img src={HandAnimatedIcon} width={80} />
          </div>}
        </div>
        <div style={{ textAlign: "center", width: "100", fontSize: "12px", paddingRight: "50px", marginTop: "3px" }}>
          Chat AI is prone to factual inaccuracies; It is advisable to verify the information.
        </div>
      </div>
    </div>
  );
};
export default OpenChatBot;