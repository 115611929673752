export const calculatePageSize = (setPagination) => {
    const screenHeight = window.innerHeight;
    let pageSize;
    if (screenHeight <= 660) {
        pageSize = 3;
    } else if (screenHeight > 660 && screenHeight <= 710) {
        pageSize = 4;
    } else if (screenHeight > 710 && screenHeight <= 760) {
        pageSize = 5;
    } else if(screenHeight > 760 && screenHeight <= 810) {
        pageSize = 6
    } else if(screenHeight > 810) {
        pageSize = 7;
    }
    setPagination((prev) => ({ ...prev, pageSize }));
}
