import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "../libs/utilities/helpers/Auth";
import setBaseURL from "../libs/utilities/helpers/BaseURLHelper";
import { encryptData } from "../libs/utilities/helpers/Crypto";
import { api } from "./config/axiosConfig";

const DEV_BASE_URL = process.env.REACT_APP_BASE_URL_LOGIN;

export const login = createAsyncThunk(
    "auth/login",
    async ({ attar = {} }, { rejectWithValue }) => {
        try {
            const encryptedPayload = await encryptData(JSON.stringify(attar));
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/user/login", encryptedPayload);
            const { data } = response.data;
            await Auth.register(data);
            const user = await Auth.getUser();
            return user;
        } catch (error) {
            // handleApiError(error, 'login');
            return rejectWithValue(error.message);
        }
    });