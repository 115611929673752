import { createSlice } from "@reduxjs/toolkit";
import { reportAnalyser } from "../apis/ReportAnalyserAPI";

const analyserSlice = createSlice({
    name: "analyser",
    initialState: {
        analyserPrompt: '',
        analyserLoading: false,
        analyserError: null,
        analyserData: [],
    },
    reducers: {
        resetAnalyserData: (state) => {
            state.analyserData = [];
        },
        setAnalyserPrompt: (state, action) => {
            state.analyserPrompt = action.payload;
        }   
    },
    extraReducers: (builder) => {
        builder
            .addCase(reportAnalyser.pending, (state) => {
                state.analyserLoading = true;
                // state.analyserData = state.analyserData;
                state.analyserError = null;
            })
            .addCase(reportAnalyser.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.analyserLoading = false;
                state.analyserData.push(data.result[0]);
                state.analyserError = null;
            }) 
            .addCase(reportAnalyser.rejected, (state, action) => {
                state.analyserLoading = false;
                // state.analyserData = state.analyserData;
                state.analyserError = action.payload;
            });
    },
});

export const { resetAnalyserData, setAnalyserPrompt } = analyserSlice.actions;
export default analyserSlice.reducer;
