import { createAsyncThunk } from "@reduxjs/toolkit";
import setBaseURL from "../libs/utilities/helpers/BaseURLHelper";
import { api } from "./config/axiosConfig";

const DEV_BASE_URL = process.env.REACT_APP_BASE_URL_OTHER_SERVICES;

export const uploadRCAReport = createAsyncThunk(
    "/summary/rca",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/summary/rca", data, {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getRCAReport = createAsyncThunk(
    "/summary/rca/report",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/summary/rca/report", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const downloadRCAReport = createAsyncThunk(
    "/summary/rca/report/download",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/summary/rca/report/download", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteRCAReport = createAsyncThunk(
    "/summary/rca/report/delete",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/summary/rca/report/delete", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const startRCAService = createAsyncThunk(
    "/summary/rca/start",
    async () => {
        console.log('hi');
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post('/summary/rca/service/start');
            console.log(response);
            return response.data;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
);

export const checkRCAStatus = createAsyncThunk(
    "/summary/rca/status",
    async () => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post('/summary/rca/service/get');
            console.log(response);
            return response.data;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
);