import { message } from "antd";
import { useCallback } from "react";

const useSuccess = () => {
    const [messageApi, contextHolder] = message.useMessage();

    const errorMessage = useCallback(
        (content, options = {}) => {
            messageApi.open({
                type: "success",
                content: content,
                ...options,
            });
        },
        [messageApi]
    );

    return {
        contextHolder,
        errorMessage,
    };
};

export default useSuccess;
