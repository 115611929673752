import React from "react";
import { Modal, Button } from "antd";
import { useNavigate } from "react-router-dom";
import "../assets/styles/gpt.scss";
import { Auth } from  "../libs/utilities/helpers/Auth";
import { useDispatch } from "react-redux";
import { toggleSessionExpireDialog } from "../reducers/sessionDialogSlice";
// Import your custom timeout image

const SessionTimeoutModal = ({ visible=true, handleOk }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  const redirectToLogin = () => {
    dispatch(toggleSessionExpireDialog({show: false}))
    // Redirect to the login page
    Auth.deregister();
    navigate("/login");
  };
  
  return (
    <Modal
      title="Session Timeout"
      open={visible}
      onOk={handleOk}
      onCancel={handleOk}
      closeIcon={null} // Remove the close icon
      footer={[
        <Button key="okay" type="primary" onClick={redirectToLogin}>
          Okay
        </Button>,
      ]}
    >
      <p>Your session has expired. Please log in again.</p>
    </Modal>
  );
};
export default SessionTimeoutModal;
