import React, { useEffect, useState } from "react";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import Input from "antd/es/input/Input";
import { Button, message, Space, Table, Pagination, DatePicker, Tooltip } from "antd";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getCallReport, uploadCallReport, downloadCallReport, deleteCallReport } from "../../apis/CallRepositoryAPI";
import UploadMedia from "./UploadMedia";
import { formatDateToYYYYMMDD } from "../../libs/utilities/helpers/dateFormatter";
import "../../assets/styles/pageStyle.scss";
import { toggleTour } from "../../reducers/tourSlice";
import PageTour from "../../components/PageTour/PageTour";
import { calculatePageSize } from "../../libs/utilities/helpers/paginationHelper";
import { hideInfoDialog, showInfoDialog } from "../../reducers/infoDialogSlice";
import InfoDialog from "../../components/InfoDialog";
const { RangePicker } = DatePicker;


const CallRepository = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [allCallReports, setAllCallReports] = useState([]);
    const [filteredCallReports, setFilteredCallReports] = useState([]);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState([]);
    const [fileType, setFileType] = useState('');
    const [fileClear, setFileClear] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 3,
        total: 0
    });
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: '',
    });
    const [uploadType, setUploadType] = useState('zip'); // Default to .zip file
    const [toggleUploadType, setToggleUploadType] = useState(false);


    const dateFormat = 'YYYY-MM-DD';
    const defaultDateRange = [
        dayjs().subtract(6, 'days'),
        dayjs()
    ];

    useEffect(() => {
        const startDate = formatDateToYYYYMMDD(dayjs().subtract(6, 'days'));
        const endDate = formatDateToYYYYMMDD(dayjs());
        setDateRange({ startDate, endDate });
        getReports(startDate, endDate);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    const handleResize = () => {
        calculatePageSize(setPagination);
    }

    const [selectedDocumentIdToDelete, setSelectedDocumentIdToDelete] = useState();

    const startDocumentDelete =     (doc_id) => {
        setSelectedDocumentIdToDelete(doc_id);
        dispatch(showInfoDialog({title: "Are you sure you want to delete?", description: ""}));
    }

    const confirmDocumentDelete = async () => {
        try {
            const response = await dispatch(deleteCallReport({_id: selectedDocumentIdToDelete}))
            if(!response.error) {
                message.success("File deleted successfully");
                getReports(dateRange.startDate, dateRange.endDate)
                dispatch(hideInfoDialog());
            }
        } catch (e) {
            message.warning("Something went wrong!");
        }
    }

    const getReports = async (startDate, endDate) => {
     
            try {
                setFetchLoading(true);
                const response = await dispatch(getCallReport({ startDate, endDate }));
                if (response && response.payload.data && response.payload.data.documents) {
                    let data = response.payload.data.documents.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                    setAllCallReports(data); // Update allCallReports state
                    setFilteredCallReports(data); // Update filteredCallReports state
                    setPagination({ ...pagination, total: data.length });
                    handleResize();
                }
            } catch (error) {
                message.error('Error fetching Call reports');
            } finally {
                setFetchLoading(false);
            }
        
    };
    const handleDownload = async (document_name, document_id) => {
        setSelectedDocument([...selectedDocument, document_name]);
        setDownloadLoading(true);
        try {
            const response = await dispatch(downloadCallReport({ '_id': document_id }));
            const binaryString = atob(response.payload.data);
            const arrayBuffer = new ArrayBuffer(binaryString.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < binaryString.length; i++) {
                uint8Array[i] = binaryString.charCodeAt(i);
            }
            // Determine the appropriate Blob type based on the file extension
            let type;
            if (document_name.endsWith('.xlsx')) {
                type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            } else if (document_name.endsWith('.zip')) {
                type = 'application/zip';
            } else {
                // Default to application/octet-stream if file type is not recognized
                type = 'application/octet-stream';
            }
            const blob = new Blob([arrayBuffer], { type: type });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', document_name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            message.success('File downloaded successfully!');
        } catch (e) {

            message.error('Failed to download report. Please try again.');
        } finally {
            setSelectedDocument((prev) => prev.filter(item => item !== document_name));
            setDownloadLoading(false);
        }
    };


    const disabledFutureDate = (current) => {
        // Disable future dates by comparing with today's date
        return current && current > dayjs().endOf('day');
    };


    const handleDateFilter = (dates, dateStrings) => {
        if (!dates) {
            return;
        }
        let startDate = formatDateToYYYYMMDD(dateStrings[0]);
        let endDate = formatDateToYYYYMMDD(dateStrings[1]);
        setDateRange({ startDate, endDate });
        getReports(startDate, endDate)();
    };

    const handleToggleUploadType = () => {
        setToggleUploadType(!toggleUploadType);
        setUploadType(toggleUploadType ? 'zip' : 'xlsx');
    };

    const handleUpload = async () => {
        if (fileList.length === 0) {
            message.warning("Please upload a file")
            return;
        }
        setLoading(true);
        try {
            const data = {
                file: fileList[0],
                type: fileType
            }
            const response = await dispatch(uploadCallReport(data));
            if (!response.error) {
                message.success("File uploaded successfully. File will display in the table after process completion")
                setFileClear((prev) => !prev);
                setFileList([]);
                getReports(dateRange.startDate, dateRange.endDate)();
            }
        } catch (e) {

        } finally {
            setLoading(false);
        }

    };

    const handleSearch = (value) => {
        setSearchText(value);
        const filteredData = allCallReports.filter(report =>
            report.document_name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCallReports(filteredData);
        setPagination({ ...pagination, total: filteredData.length });
    };
    const handleChange = (file, fileType) => {
        setFileList(file);
        if (fileType == ".xlsx") {
            setFileType("xlsx")
        } else {
            setFileType("zip")
        }
    }

    const columns = [
        {
            title: 'Call Repository',
            dataIndex: 'document_name',
            key: 'document_name',
            sorter: (a, b) => a.document_name.localeCompare(b.document_name)
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => dayjs(createdAt).format('YYYY-MM-DD'),
            // sorter: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
            // defaultSortOrder: 'desc',
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="small">
                    {
                        // record.is_processed ?
                        <Tooltip
                            placement="top" title="Download document"
                        >
                            <Button type="text"
                                loading={selectedDocument.some(item => item === record.document_name, record._id) &&
                                    downloadLoading} onClick={() => handleDownload(record.document_name, record._id)}
                            >
                                <DownloadOutlined />
                            </Button>
                        </Tooltip>
                        // :
                        // <Tooltip title="Document not generated">
                        //     <Button style={{ width: "73px" }} type="text"><ExclamationCircleOutlined style={{ color: "gray" }} /></Button>
                        // </Tooltip>
                    }
                    <Tooltip title="Delete document">
                        <Button onClick={() => startDocumentDelete(record._id)} type="text">
                            <DeleteOutlined/>
                        </Button>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handlePageChange = (page, pageSize) => {
        setPagination({ ...pagination, current: page, pageSize });
    };

    const tourState = useSelector((state) => state.tour);
    const handleTourClose = () => {
        dispatch(toggleTour({ show: false }))
    }


    return (
        <>
            <div className="call-repository call-repository-container">
                <InfoDialog onConfirm={confirmDocumentDelete} />
                <PageTour show={tourState.show} handleShow={handleTourClose} target="tourshow" title="Start uploading call recording zip file  or xlsx file to the repository" />
                <div className="file-table">
                    <div className="table-actions">
                        <Input
                            size="middle"
                            value={searchText}
                            onChange={(e) => handleSearch(e.target.value)} // Update handleSearch function
                            placeholder="Search"
                        />
                        <RangePicker
                            className="file-datepicker"
                            onChange={handleDateFilter}
                            defaultValue={defaultDateRange}
                            format={dateFormat}
                            allowClear={false}
                            disabledDate={disabledFutureDate} // Disable future dates
                        />
                    </div>

                    <Table
                        columns={columns}
                        dataSource={filteredCallReports}
                        loading={fetchLoading}
                        style={{ overflowY: "auto" }}
                        pagination={pagination}
                        rowKey={(record) => record.document_name} // Assuming document_name is unique
                    />


                    <Pagination className="pagination"
                        current={pagination.current}
                        pageSize={pagination.pageSize}
                        total={pagination.total}
                        onChange={handlePageChange} style={{ marginTop: "5px", float: "right" }} />

                </div>
                {/* ======= */}
                <div className="tourshow">
                    <UploadMedia
                        handleFileSelect={handleChange} // Update handleFileSelect function
                        fileList={fileList}
                        toggleUploadType={toggleUploadType}
                        handleToggleUploadType={handleToggleUploadType}
                        fileClear={fileClear}
                    />

                    <div className="actions">
                        <Button type="primary" onClick={handleUpload} disabled={loading}>
                            <div className='text'>Submit</div>
                        </Button>
                    </div>
                </div>
            </div>

        </>
    );
};

export default CallRepository;
