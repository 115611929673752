import store from '../../store/configureStore';
import axios from "axios";
import { message } from 'antd';
import { toggleSessionExpireDialog } from "../../reducers/sessionDialogSlice";
import errorCodes from '../../libs/utilities/helpers/errorCodes';
import { toggleComponentErrorPage } from '../../reducers/componentErrorSlice';
import { Auth } from "../../libs/utilities/helpers/Auth";

export const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_default_API_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "content-type": "application/json",
    },
});

api.interceptors.request.use(
    (config) => {
        // Add authorization header to every request
        const token = Auth.getToken();
        const user = Auth.getUser();
        if (token) {
            config.headers.Authorization = token;
        }
        if (user) {
            config.headers.txn_id = user.Txnid;
            config.headers.user_id = user.UserId;
            config.headers.source = 'Galaxy';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const apiCaller = error.config.url.split("/").slice(-1)[0];
        handleApiError(error, apiCaller === 'login' ? 'login' : 'default');
        return Promise.reject(error);
    }
);


export const handleApiError = (error, source) => {
    const errorCode = error.code;

    if (errorCode === "ERR_NETWORK") {
        if (source === 'login') {
            window.location.href = "./error";
        } else {
            store.dispatch(toggleComponentErrorPage({ show: true }));
        }
    }

    if (error.response) {
        const status = error.response.status;
        const code = error.response.data.statusCode;
        const errmessage = error.response.data.message
        if (status === 401 && code === 'ERR0002') {
            store.dispatch(toggleSessionExpireDialog({ show: true }))
        }
        else if (status === 401 && code === 'ERR0035') {
            message.error(errmessage);
        }
        else if (status === 500) {
            if (source == "login") {
                window.location.href = "./error";
            } else {
                message.error('Something went wrong . Please try again later.');
            }
        } else if (status === 400) {
            if (errorCodes[code] !== undefined) {
                message.error(errorCodes[code]);
            }
        }
        else if (status === 404) {
            store.dispatch(toggleComponentErrorPage({ show: true }));
        } else if (status === 408) {
            let urls = ['enterprise-gpt', 'chat-ai'];
            let currentRoute = window.location.href.split('/').slice(-1)[0];
            if (urls.includes(currentRoute)) {
                message.error('Our servers are currently experiencing high traffic. Please try after some time.')
            }
        } else {
            // Handle other error statuses if needed
            message.error('Something went wrong . Please try again later.');
        }
    }
    throw error;
};

