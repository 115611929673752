import {
  UpCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, message, TreeSelect } from "antd";
import { Content } from "antd/es/layout/layout";
import Modal from "antd/es/modal/Modal";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPdfList, removePdf } from "../../apis/DocGPTAPI";
import "../../assets/styles/gpt.scss";
import Chatbot2 from "../../components/chatbot2";
import TourDocGPT from "../../components/TourDocGPT/TourDocGPT";
import UploadFolderModal from "../../components/UploadFolderModal";
import { toggleTour } from "../../reducers/tourSlice";
import DropdownFileItem from "./DropdownFileItem";

const Gpt = () => {
  const dispatch = useDispatch();

  const [sessionTimeoutVisible, setSessionTimeoutVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [folderList, setFolderList] = useState([]);
  const [dialogData, setDialogData] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [deletionLoading, setDeleteLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const tourState = useSelector((state) => state.tour);
  const [showHandAnimation, setShowHandAnimation] = useState(false);

  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    getFileList();
  }, []);

  useEffect(() => {
    let timeout;
    if (tourState.show) {
      timeout = setTimeout(() => {
        setShowHandAnimation(true);
      }, 1000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [tourState])

  const getFileList = () => {
    // Fetching folders and files
    try {
      dispatch(getPdfList()).then((response) => {
        if (response.meta?.requestStatus === "fulfilled") {
          let data = response.payload.map((item, index) => {
            let data = {
              title: <DropdownFileItem folder={item} file="" itemType="folder" confirmDelete={(data) => openConfirmatinDialog(data)} />,
              value: item.folder_id,
              key: item.folder_id,
              children: item.file.map((file, count) => {
                return {
                  title: <DropdownFileItem folder={item} file={file} itemType="file" confirmDelete={(data) => openConfirmatinDialog(data)} />,
                  value: file.id,
                  key: file.id
                }
              })
            };
            return data;
          })

          setTreeData(data.filter(item => item.children.length > 0))
          setFolderList(response.payload);
          // if (response.payload.length == 0) {
          //   dispatch(toggleTour({ show: true }))
          // }
        }

      });
    } catch (error) {
      // error
    }
  }

  const toggleSessionTimeout = () => {
    setSessionTimeoutVisible(!sessionTimeoutVisible);
  };

  const toggleModalVisibility = () => {
    setIsModalVisible(!isModalVisible);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onChange = (value) => {
    setSelectedFile(value);
  };

  const openConfirmatinDialog = (data) => {
    setDialogData(data);
    setShowDialog(true);
  }

  const handleDeleteConfirmation = async () => {
    let params;
    if (dialogData.type === "folder") {
      let folder_id = dialogData.data.folder_id;
      let file_list = dialogData.data.file.map(file => file.id);
      params = {
        type: "folder",
        file_list: file_list,
        folder_id: folder_id
      };
    } else {
      let folder_id = dialogData.data.folder.folder_id;
      let file_id = dialogData.data.file.id;
      params = {
        type: "file",
        file_list: file_id ? [file_id] : [],
        folder_id: folder_id,
      };
    }

    // return;
    try {
      setDeleteLoading(true);
      const response = await dispatch(removePdf(params));
      if (response.payload?.statusCode === 200) {
        message.success(`${dialogData.type === "folder" ? "Folder" : "File"} deleted successfully.`);
        // ================ Managing Local State ============================ //
        if (dialogData.type === "folder") {
          let tempData = treeData.filter(item => item.key != dialogData.data.folder_id);
          setFolderList((prev) => {
            return prev.filter(item => item.folder_id !== dialogData.data.folder_id);
          })
          setTreeData(tempData.filter(item => item.children.length > 0))
          setShowDialog(false);
        } else {
          let folder_id = dialogData.data.folder.folder_id;
          let file_id = dialogData.data.file.id;

          // Modifying the dropdown tree list
          let tempData = [...treeData];
          let obj = tempData.find(item => item.key === folder_id);
          obj.children = obj.children.filter(item => item.key !== file_id);

          let findIndex = tempData.findIndex(item => item.key === obj.key);
          tempData[findIndex] = obj;

          // modifying the folderList
          const tempObj = { ...folderList.find(item => item.folder_id === folder_id) }
          tempObj.file = tempObj.file.filter(item => item.id !== file_id);

          const fileIndex = folderList.findIndex(item => item.folder_id === folder_id);
          let updatedFolderList = [...folderList]

          updatedFolderList[fileIndex] = tempObj;


          setFolderList(updatedFolderList)

          setTreeData(tempData.filter(item => item.children.length > 0))

          setShowDialog(false);
        }
        setSelectedFile([])
        // ================== Eng of managing state ======================== //
      }

      setShowDialog(false);
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      setShowDialog(false);
    }
  }

  const handleGetListRefresh = () => {
    getFileList();
  }

  const [tourStep, setTourStep] = useState(0);
  const chatInput = document.getElementsByClassName('chat-input');

  const handleInputFocus = () => {
    dispatch(toggleTour({ show: false }))
    setShowHandAnimation(false);
    chatInput[0].style.zIndex = "inherit";
    const timeout = setTimeout(() => {
      setTourStep(0);
      clearTimeout(timeout);
    }, 500);
  }

  const handleChangeStep = (step) => {
    if (step === 1) {
      setTourStep(step);
      chatInput[0].style.zIndex = 9999;
    }
    if (step === 2) {
      handleInputFocus();
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>

      <TourDocGPT
        show={tourState.show}
        handleShow={handleInputFocus}
        page="enterprise-gpt"
        tourStep={tourStep}
        changeStep={handleChangeStep}
      />

      <div className="cards">
        <Content className="content">
          <Card className="big-card" size="large">

            <div className="select-boxes">
              <TreeSelect
                style={{ width: "100%" }}
                // open={true}
                treeCheckable={true}
                value={selectedFile}
                dropdownStyle={{ zIndex: "999" }}
                virtual={false}
                treeData={treeData}
                placeholder="Select Folder/File"
                allowClear
                showSearch={false}
                variant="borderless"
                treeDefaultExpandAll
                onChange={onChange}
                onClick={() => { setOpenDropdown((prev) => !prev) }}
                onBlur={() => { setOpenDropdown(false) }}
              />
            </div>

            <div className="card-uploadbutton">
              <div className="btn-wrapper">
                <Button type="primary2" id="upload-documents" onClick={showModal}>
                  Upload Documents <UpCircleOutlined />
                </Button>
              </div>
            </div>

          </Card>
          {/* Modal for Upload */}
          <div>
            <UploadFolderModal
              showModal={isModalVisible}
              toggleModalVisibility={toggleModalVisibility}
              folderList={folderList}
              refreshFolderList={() => { handleGetListRefresh() }}
            />
          </div>
        </Content>
      </div>
      <div style={{ flex: 1, height: "100%" }}>
        <Chatbot2
          selectedFile={selectedFile}
          folderList={folderList}
          showTour={(tourState.show && showHandAnimation && tourStep === 1)}
          handleInputFocus={handleInputFocus}
        />
      </div>
      <Modal
        title={`Delete ${dialogData && (dialogData.type === "folder" ? 'Folder' : 'File')}`}
        open={showDialog}
        onOk={handleDeleteConfirmation}
        onCancel={() => { setShowDialog(false) }}
        confirmLoading={deletionLoading}
        closeIcon={null} // Remove the close icon
        footer={[
          <Button key="cancel" type="text" onClick={() => setShowDialog(false)} disabled={deletionLoading}>
            Cancel
          </Button>,
          <Button key="okay" type="primary" onClick={handleDeleteConfirmation} disabled={deletionLoading}>
            {deletionLoading ? "Loading..." : "Delete"}
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete this {dialogData && (dialogData.type === 'folder' ? "folder" : "file")}?</p>
      </Modal>
    </div>
  );
};

export default Gpt;
