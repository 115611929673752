import { createAsyncThunk } from "@reduxjs/toolkit";
import setBaseURL from "../libs/utilities/helpers/BaseURLHelper";
import { api } from "./config/axiosConfig";

const DEV_BASE_URL = process.env.REACT_APP_BASE_URL_PROCESS_GURU;

export const getPdfList = createAsyncThunk(
    "process/guru/list",
    async () => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/process/guru/list", {});
            // const filteredData = response.data.data.map(obj => ({
            //     ...obj,
            //     file: obj.file.filter(fileObj => fileObj.embedded !== false)
            // }));
            const filteredData = response.data.data
            return filteredData;
        } catch (error) {
            throw error;
        }
    }
);

export const removePdf = createAsyncThunk(
    "process/guru/delete",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/process/guru/delete", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const generateAnswer = createAsyncThunk(
    "process/guru/generate",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/process/guru/conversation", data);
            return response.data;
        } catch (error) {
            // console.log(error)
            return rejectWithValue(error.response.data);
        }
    }
);

export const getPdf = createAsyncThunk(
    "process/guru/list",
    async (data) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post(`/process/guru/list/${data.fileName}`, {folder_name: data.folderName});
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const uploadPdf = createAsyncThunk(
    "/process/guru/upload",
    async ({ data = {} }, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/process/guru/upload", data, {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const getPromptLibraryProcessAlly = createAsyncThunk(
    "/process/guru/prompt/library",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL)
            const response = await api.get("/process/guru/prompt/library");
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const saveFeedback = createAsyncThunk(
    "/process/guru/feedback",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/process/guru/feedback", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)