import { configureStore } from "@reduxjs/toolkit";
import analyserSlice from "../reducers/analyserSlice";
import authSlice from "../reducers/authSlice";
import openChatSlice from "../reducers/openChatSlice";
import sessionDialogSlice from "../reducers/sessionDialogSlice";
import infoDialogSlice from "../reducers/infoDialogSlice";
import tourSlice from "../reducers/tourSlice";
import promptSlice from "../reducers/promptSlice";
import componentErrorSlice from "../reducers/componentErrorSlice";
 
const store = configureStore({
    reducer: {
        analyser: analyserSlice,
        auth: authSlice,
        openChat: openChatSlice,
        sessionDialog: sessionDialogSlice,
        infoDialog: infoDialogSlice,
        tour: tourSlice,
        prompt: promptSlice,
        componentError: componentErrorSlice ,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
