import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import "../assets/styles/ErrorBoundary.scss";

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service here
        // console.error('hi', error, errorInfo);
    }


    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary">
                    <InfoCircleOutlined style={{fontSize: "40px", color: "#BB2124"}} />
                    <div className="title">Something went wrong</div>
                    <div className="description">Explore other available services</div>
                    {/* <Button type="primary" className="button">Return to Login</Button> */}
                </div>
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
