import { createAsyncThunk } from "@reduxjs/toolkit";
import setBaseURL from "../libs/utilities/helpers/BaseURLHelper";
import { api } from "./config/axiosConfig";

const DEV_BASE_URL = process.env.REACT_APP_BASE_URL_OTHER_SERVICES;

export const getReportList = createAsyncThunk(
    "/report/list",
    async ({ data = {} }, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/report/list", data, {
                headers: {
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const getInfoRetrieval = createAsyncThunk(
    "/report/information/retrieval",
    async ({ data = {} }, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/report/information/retrieval", data, {
                headers: {
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const uploadReportPdf = createAsyncThunk(
    "/report/save",
    async ({ data = {} }, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/report/save", data, {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export const deleteReportPdf = createAsyncThunk(
    "/report/delete",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/report/delete", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
