import { createAsyncThunk } from "@reduxjs/toolkit";
import setBaseURL from "../libs/utilities/helpers/BaseURLHelper";
import { api } from "./config/axiosConfig";

const DEV_BASE_URL = process.env.REACT_APP_BASE_URL_DOC_GPT;

export const getPdfList = createAsyncThunk(
    "document/gpt/list",
    async () => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/document/gpt/list", {});
            const filteredData = response.data.data.map(obj => ({
                ...obj,
                file: obj.file.filter(fileObj => fileObj.embedded !== false)
            }));
            return filteredData;
        } catch (error) {
            throw error;
        }
    }
);

export const getPdf = createAsyncThunk(
    "document/gpt/list",
    async (data) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post(`/document/gpt/list/${data.fileName}`, {folder_name: data.folderName});
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

export const removePdf = createAsyncThunk(
    "document/gpt/delete",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/document/gpt/delete", data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const generateAnswer = createAsyncThunk(
    "document/gpt/generate",
    async (data, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/document/gpt/conversation", data);
            return response.data;
        } catch (error) {
            // console.log(error)
            return rejectWithValue(error.response.data);
        }
    }
);

export const uploadPdf = createAsyncThunk(
    "/document/gpt/upload",
    async ({ data = {} }, { rejectWithValue }) => {
        try {
            setBaseURL(api, DEV_BASE_URL);
            const response = await api.post("/document/gpt/upload", data, {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
