import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import "../assets/styles/header.scss";
import '../assets/styles/dailySummarizer.scss';
import "../assets/styles/generator.scss"
import Login from "../pages/Login";
import PrivateRoute from "./PrivateRoute";
import Gpt from "../pages/document-gpt/Gpt";
import MainLayout from "../layout/MainLayout";
import OpenChat from "../pages/OpenChat";
import PCVC from "../pages/pcvc/PCVC";
import ComplaintAnalyser from "../pages/rca-cis/rca";
import EarlyClaims from "../pages/early-claims/EarlyClaims";
import ErrorPage from "../pages/Error-page/ErrorPage";
import ReportGenerator from "../pages/reportgenerator/Reportgenerator";
import ReportAnalyser from "../pages/report-analyser/ReportAnalyser";
import CallRepository from "../pages/CallRepository/CallRepository";
import ErrorBoundary from "../components/ErrorBoundary";
import ProcessGuru from "../pages/process-guru/ProcessGuru";
import DefaultRoute from "./DefaultRoute";

const router = createBrowserRouter([
  {
    path: "/error",
    element: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
  }, {
    path: "*",
    element: <Navigate to="/" />
  },
  {
    path: "/",
    element: <PrivateRoute element={<MainLayout />} />,
    children: [
      {
        path: "",
        element: <DefaultRoute />
      },

      {
        path: "chat-ai",
        element: <ErrorBoundary key="1"><OpenChat /></ErrorBoundary>,
      },
      {
        path: "report-analyser",
        element: <ErrorBoundary key="2"><ReportAnalyser /></ErrorBoundary>,
      },
      {
        path: "report-generator",
        element: <ErrorBoundary key="3"><ReportGenerator /></ErrorBoundary>,
      },
      {
        path: "complaint-analyser",
        element: <ErrorBoundary key="4"><ComplaintAnalyser /></ErrorBoundary>,
      },
      {
        path: "enterprise-gpt",
        element: <ErrorBoundary key="5"><Gpt /></ErrorBoundary>,
      },
      {
        path: "process-buddy",
        element: <ErrorBoundary key="6"><ProcessGuru /></ErrorBoundary>,
      },
      {
        path: "pcvc",
        element: <ErrorBoundary key="7"><PCVC /></ErrorBoundary>,
      },
      {
        path: "pre-claims",
        element: <ErrorBoundary key="8"><EarlyClaims /></ErrorBoundary>
      },
      {
        path: "call-repository",
        element: <ErrorBoundary key="9"><CallRepository /></ErrorBoundary>
      },
    ]
  },

]);

export default router;
