import { useState, useCallback, useEffect } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { LoadingOutlined, UpCircleOutlined } from '@ant-design/icons';
import '../assets/styles/gpt.scss';
import { Modal, Select, Upload, Button, Radio, Input, message, Spin } from 'antd';
import { uploadPdf } from "../apis/DocGPTAPI";
import useSuccess from "../hooks/successMessage.js";
import ArrowUpIcon from "../assets/images/icons/arrow_up.svg";
// import { NULL } from 'sass';

const { Dragger } = Upload;
const { Option } = Select;

export const defaultFolder = [
    {
        name: "Product Brochures",
        id: "1",
        default: true,
        files: []
    },
    {
        name: "HR Processes",
        id: "3",
        default: true,
        files: []
    },
    {
        name: "Process Notes",
        id: "4",
        default: true,
        files: []
    },
    {
        name: "Financial Reports",
        id: "5",
        default: true,
        files: []
    },
    {
        name: "Others",
        id: "6",
        default: true,
        files: []
    },
]

const UploadFolderModal = ({ showModal, toggleModalVisibility, folderList, refreshFolderList }) => {
    const dispatch = useDispatch();
    const { errorMessage, contextHolder } = useSuccess();
    const [selectedUploadFolder, setSelectedUploadFolder] = useState("");
    const [folderName, setFolderName] = useState('');
    const [radioValue, setRadioValue] = useState("file");

    const [selectedFile, setSelectedFile] = useState([]);
    const [loading, setLoading] = useState(false);

    // const [uploadFilesName, setUploadedFilesName] = useState([]);
    const [allFolder, setAllFolder] = useState(defaultFolder)

    const [isDuplicateFileError, setIsDuplicateFileError] = useState(false)
    const [duplicateFileNames, setDuplicateFileNames] = useState([]);

    const [uploadRequestObject, setUploadRequestObject] = useState({
        embedding_type: "e5-large-v2",
        folder_name: '',
        folder_id: '',
        type: 'file'
    });

    useEffect(() => {
        // mapping server list : {name, id, default}
        let folders = folderList.map(item => ({ name: item.folder_name, id: item.folder_id, default: false, files: item.file }));

        // Checking if default folder already exists in server then removing from local default folder list
        let newDefaultFolder = defaultFolder.filter(item => !folders.some(existingItem => existingItem.name === item.name))
        let tempAllFolders = [...newDefaultFolder, ...folders].sort(customSort);
        setAllFolder(tempAllFolders)

        setSelectedUploadFolder(tempAllFolders[0].id)
        setUploadRequestObject((pre) => {
            return { ...pre, folder_name: "", folder_id: tempAllFolders[0].id };
        });
    }, [folderList])

    // Putting 'Others' category at last of category list
    const customSort = (a, b) => {
        if (a.name === "Others") {
            return 1;
            // "others" comes after all other elements  
        } else if (b.name === "Others") {
            return -1;
            // "others" comes before all other elements  
        } else {
            return 0; // no change in order for other elements  
        }
    };




    const beforeUpload = (file) => {
        if (file.type !== "application/pdf") {
            message.error("File should be in PDF format")
            return Upload.LIST_IGNORE;;
        }

        if ((file.size / 1024 / 1024) > 100) {
            message.error('File must be smaller than 100MB!');
            return Upload.LIST_IGNORE;
        }

        const isDuplicate = selectedFile.some(existingFile => existingFile.name === file.name);
        if (isDuplicate) {
            setDuplicateFileNames((prev) => [...prev, file.name]);
            // message.error('File already selected');
            setIsDuplicateFileError(true)
            return Upload.LIST_IGNORE;
        }

        if (radioValue == "folder") {
            return true;
        }

        // If folder is selected before selecting file, check if the file is exists or not in the selected folder
        if (selectedUploadFolder) {
            const existingFileForSelectedFolder = allFolder.find(item => item.id === selectedUploadFolder).files.map(item => item.name);
            const newFiles = existingFileForSelectedFolder.some(item => item === file.name);

            if (newFiles) {
                setIsDuplicateFileError(true);
                setDuplicateFileNames(prev => [...prev, file.name])
                return Upload.LIST_IGNORE;
            }
        }

        return true;
    }

    const handleFileChange = (file) => {
        const data = file.fileList.map(item => item.originFileObj ? item.originFileObj : item)
        setSelectedFile(data)
    }

    const customRequest = ({ file, onSuccess }) => {
        if (file && file.name) {
            onSuccess("ok");
        }
    }


    const handleFileRemove = (file) => {
        setIsDuplicateFileError(false);
        setDuplicateFileNames([])
        // const newFileList = selectedFile.filter(item => item.uid !== file.uid);
        // setSelectedFile(newFileList);
    }

    const handleFolderName = (e) => {
        setFolderName(e.target.value);
        setUploadRequestObject((pre) => {
            return { ...pre, folder_name: e.target.value }
        });
    };

    const handleRadioChange = (e) => {
        setIsDuplicateFileError(false);
        setDuplicateFileNames([])
        setRadioValue(e.target.value);
        let folder_name;
        if (e.target.value == 'file') {
            folder_name = selectedUploadFolder;
            if (selectedUploadFolder) {
                filterDuplicateFiles(selectedUploadFolder);
            }
        } else {
            folder_name = folderName;
        }
        setUploadRequestObject((pre) => {
            return { ...pre, type: e.target.value, folder_name: folder_name };
        });
    };

    const onSelectFolder = (folderId) => {
        setSelectedUploadFolder(folderId);
        setUploadRequestObject((pre) => {
            return { ...pre, folder_name: "", folder_id: folderId };
        });

        setIsDuplicateFileError(false);
        setDuplicateFileNames([])

        if (selectedFile.length > 0) {
            filterDuplicateFiles(folderId)
        }
    };

    const filterDuplicateFiles = (folderId) => {

        const existingFileForSelectedFolder = allFolder.find(item => item.id === folderId).files.map(item => item.name);
        const data = selectedFile.filter(item => {
            if (existingFileForSelectedFolder.includes(item.name)) {
                setDuplicateFileNames(prev => [...prev, item.name])
                return false;
            } else {
                return true;
            }
        });

        if (!arraysAreEqual(data, selectedFile)) {
            setIsDuplicateFileError(true)
        }
        setSelectedFile(data)
    }

    function arraysAreEqual(array1, array2) {
        if (array1.length !== array2.length) { return false; }
        // Sort the arrays based on the serialized representation of each object  
        const sortedArray1 = array1.slice().sort((a, b) => JSON.stringify(a) > JSON.stringify(b) ? 1 : -1);
        const sortedArray2 = array2.slice().sort((a, b) => JSON.stringify(a) > JSON.stringify(b) ? 1 : -1);
        // Use every to check if each object in the sorted arrays is equal  
        return sortedArray1.every((obj, index) => JSON.stringify(obj) === JSON.stringify(sortedArray2[index]));
    }

    const handleUpload = async () => {
        setIsDuplicateFileError(false);
        setDuplicateFileNames([]);
        // setUploadedFilesName([]);
        if (selectedFile.length === 0) {
            message.warning("Please select atleast one file.")
            return;
        }

        let reqData;
        if (uploadRequestObject.type === 'file') {
            // uploading to the existing folder
            if (!selectedUploadFolder) {
                message.warning("Please select folder")
                return;
            }

            // check if default folder is selected
            if (defaultFolder.some(item => item.id === uploadRequestObject.folder_id)) {
                // User is trying to upload on default folder which does not exists in the server
                // Only folder name required
                reqData = {
                    file: selectedFile,
                    embedding_type: "e5-large-v2",
                    folder_name: allFolder.find(item => item.id === uploadRequestObject.folder_id).name,
                    type: "folder"
                }
            } else {
                // User is trying to upload on folder which already exists on folder
                // Folder id = required
                reqData = {
                    file: selectedFile,
                    embedding_type: "e5-large-v2",
                    folder_name: allFolder.find(item => item.id === uploadRequestObject.folder_id).name,
                    folder_id: uploadRequestObject.folder_id,
                    type: uploadRequestObject.type
                }
            }


        } else {
            // uploading with new folder
            if (!folderName) {
                message.warning('Please enter folder name')
                return;
            }
            reqData = {
                file: selectedFile,
                embedding_type: "e5-large-v2",
                folder_name: uploadRequestObject.folder_name,
                type: uploadRequestObject.type
            }
        }

        try {
            setLoading(true);
            const response = await dispatch(uploadPdf({ data: reqData }));
            if (response.payload.statusCode === 200 && response.payload.message === 'Success') {
                message.success("File upload is in progress. Document will display in the list after a successful upload");
                refreshFolderList()
                closeModal();
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const closeModal = () => {
        toggleModalVisibility();
        setSelectedFile([]);

        // clearing folder name typed by user
        setFolderName("");

        // setSelectedUploadFolder("")
        setIsDuplicateFileError(false);
        setDuplicateFileNames([])
    }

    return (
        <>
            <Modal
                title="Upload Documents"
                open={showModal}
                onCancel={null}
                closable={false}
                onOk={handleUpload}
                footer={[
                    <Button key="cancel" type="text" onClick={closeModal} disabled={loading}>Cancel</Button>,
                    <Button key="upload" type="primary" onClick={handleUpload} disabled={loading}>Upload</Button>,
                ]}
                width={600}
                className="upload-folder-modal"
            >
                {
                    loading ?
                        <div className="loading-content">
                            <div className="spinner">
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color: "#53de49" }} spin />} />
                            </div>
                            <p>File uploading. Please wait...</p>
                        </div>
                        :
                        <>
                            <Dragger
                                name="file"
                                multiple={true}
                                fileList={selectedFile}
                                accept=".pdf"
                                listType="picture"
                                beforeUpload={beforeUpload}
                                onRemove={handleFileRemove}
                                onChange={handleFileChange}
                                customRequest={customRequest}
                                className='' style={{ border: '2px dashed #1890ff' }}>
                                <div className='modal-upload-dragger' onClick={() => { setIsDuplicateFileError(false); setDuplicateFileNames([]); }}>
                                    <p>Drag and drop here or</p>
                                    <button className="btn-outlined">
                                        <div className='btn-outlined-content'>
                                            <div className='text'>Upload documents </div>
                                            <img width={29} src={ArrowUpIcon} />
                                        </div>
                                    </button>
                                </div>
                            </Dragger>

                            <Radio.Group value={radioValue} onChange={handleRadioChange} className="modal-radio-group">
                                <Radio value="file">What type of document is this?</Radio>
                                <Radio value="folder">Create document type</Radio>
                            </Radio.Group>

                            <div style={{ position: "relative" }}>
                                {radioValue === "folder" ? (
                                    <div className='selectfolderbox2'>
                                        {/* <div className="title">Enter folder name</div> */}
                                        <Input className="folder-name-input"
                                            value={folderName}
                                            onChange={handleFolderName}
                                            placeholder="Enter document type"
                                        />
                                    </div>
                                ) : (
                                    <div className='selectfolderbox2'>
                                        {/* <div className='title'>Select folder</div> */}
                                        <Select
                                            style={{ width: '100%', height: "40px" }}
                                            placeholder="Select Folder"
                                            onChange={onSelectFolder}
                                            variant="outlined"
                                            value={selectedUploadFolder}
                                        >
                                            {allFolder?.map(folder => (
                                                <Option key={folder.id} value={`${folder.id}`}>
                                                    {folder.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                )}
                                <small style={{ color: "#cc3300", position: "absolute" }}>
                                    {isDuplicateFileError ? (duplicateFileNames.length > 0 ? 'Below duplicate files were removed' : 'Duplicate files were removed') : ""}
                                </small>
                                <div className="duplicate-files">
                                    {duplicateFileNames.map((item, index) => <small key={index}>&#x2022; {item}</small>)}
                                </div>
                            </div>

                        </>
                }
            </Modal>
        </>
    )
}

export default UploadFolderModal;