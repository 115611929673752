import { Layout } from "antd";
import Sidebar from "../components/Sidebar";
import { Outlet } from "react-router-dom";
import CustomHeader from "../components/CustomHeader";
import { useSelector } from "react-redux";
import SessionTimeoutModal from "../components/SessionTimeoutModal";
import ErrorComponent from "../pages/Error-page/ErrorComponent";
import { useState } from "react";

const { Sider, Content } = Layout;

const MainLayout = () => {
  const sessionDialog = useSelector(state => state.sessionDialog);
  const showError = useSelector(state => state.componentError).show;
  const [collapsed, setCollapsed] = useState(false);


  return (
    <div>
      <Layout className="main-layout">
        <Sider style={{ background: "transparent" }}
          breakpoint="lg"
          collapsedWidth="60"
          onBreakpoint={(broken) => {
            setCollapsed(broken);
          }}
          width="270"
        >
          <Sidebar collapsed={collapsed} />
        </Sider>
        <Layout style={{ background: "#EDEFF7" }}>
          {/* Header */}
          <CustomHeader collapsed={collapsed} />
          {
            showError ? <ErrorComponent /> :

              <Content className="main-content">
                <div style={styles.wrapper}>
                  <Outlet />
                  <SessionTimeoutModal
                    visible={sessionDialog.show}
                    handleOk={() => { }}
                  />
                </div>
              </Content>
          }
        </Layout>
      </Layout>

    </div>
  );
};

const styles = {
  wrapper: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
};

export default MainLayout;

