import { React, useRef } from "react";
import { useEffect } from "react";
import "../assets/styles/gpt.scss";
import { useState } from "react";
import OpenChatBot from "../components/OpenChatBot";
import { Auth } from "../libs/utilities/helpers/Auth";
import { getUserChatHistory, getChatHistoryById } from "../apis/ChatAIAPI";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import PromptLibrary from "./prompt-library/PromptLibrary";
import { togglePrompt } from "../reducers/promptSlice";
import {  useLocation } from "react-router-dom";

const OpenChat = () => {
  const chatLIstRef = useRef(null);
  const currentUrl = window.location.href;
  const dispatch = useDispatch();
  const [conversationHistory, setConversationHistory] = useState([]);
  const [historyChat, setHistoryChat] = useState([]);
  const user = Auth.getUser();
  const openChat = useSelector((state) => state.openChat);
  const showPrompt = useSelector((state) => state.prompt).show;
  const [prompt, setPrompt] = useState('');
  const location = useLocation();

  const accessibility = user.accessibility.filter(item => item.status);
  const activeServices = accessibility.map(service => service.service);
  const createNewChat = () => {
    let newChatId = uuidv4();
    sessionStorage.setItem("conversation_id", newChatId);
    sessionStorage.setItem("currentChatId", newChatId);
    setHistoryChat([]);
  };

  useEffect(() => {
    if (activeServices.includes('chat-ai') && location.pathname === '/chat-ai') {
      dispatch(getChatHistoryById())
        .then((response) => {
          if (response?.payload?.data[0]?.conversation != undefined)
            setHistoryChat(response?.payload?.data[0]?.conversation);
          else {
            setHistoryChat([]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      dispatch(getUserChatHistory())
        .then((userChatResponse) => {
          setConversationHistory(userChatResponse.payload.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);


  const getPrompt = (value) => {
    setPrompt(value.query)
    dispatch(togglePrompt({ show: false }))
  }

  return (
    <>
      <PromptLibrary showPrompt={showPrompt} getPrompt={getPrompt} page="chat-ai" />
      <OpenChatBot showPrompt={showPrompt} historyChat={openChat?.currentChatHistory} prompt={prompt} />
    </>
  );
};

export default OpenChat;
