import { createSlice } from "@reduxjs/toolkit";
import { getPromptLibrary } from '../apis/ChatAIAPI';

const promptSlice = createSlice({
    name: 'prompt',
    initialState: {
        show: false,
        prompts: [],
        status: "idle",
        error: null
    },
    reducers: {
        togglePrompt: (state, action) => {
            state.show = action.payload.show;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPromptLibrary.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPromptLibrary.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.prompts = action.payload.data;
            })
            .addCase(getPromptLibrary.rejected, (state, action) => {
                state.status = 'failed';
                state.error = "Something went wrong!";
            })
    }
})

export const { togglePrompt } = promptSlice.actions;

export default promptSlice.reducer